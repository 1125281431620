import { useMutation } from "react-query";
import {
  IndexRequestParams,
  updateFleetPremiumForEngineTax20250401,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const updateFleetPremiumForEngineTax20250401Fn = async (
  params: IndexRequestParams
) => {
  return await updateFleetPremiumForEngineTax20250401(params);
};

export function useUpdateFleetPremiumForEngineTax20250401() {
  return useMutation(updateFleetPremiumForEngineTax20250401Fn, {
    mutationKey: MUTATION_KEYS.updateFleetPremiumForEngineTax20250401,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
