import React from "react";
import { useFields } from "../../../components/form/useFields";
import { useSelector } from "../../../store/hooks";
import { PremiumType, TimelineDialogType } from "../../../types/types";
import FleetLegalFormContent from "../components/FleetLegalFormContent";
import { useAnnualNetPremium } from "../hooks/useAnnualNetPremium";
import { useDefaultSettings } from "../hooks/useDefaultSettings";
import { useInsurerSettings } from "../hooks/useInsurerSettings";
import { modifyPremiumDialogConfig } from "./modifyPremiumDialogConfig";

const config = modifyPremiumDialogConfig;

interface IProps {
  premiumTypeKey: string;
  dialogType: TimelineDialogType;
}

export const ModifyPremiumDialogContents = ({
  premiumTypeKey,
  dialogType,
}: IProps) => {
  const item = useSelector((s) => s.timeline.dialog.item);

  useAnnualNetPremium(premiumTypeKey);
  useDefaultSettings();
  useInsurerSettings();
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const suspensionDaysIncluded = appSettings?.SUSPENSION_DAYS_INCLUDED;
  const activationDaysIncluded = appSettings?.ACTIVATION_DAYS_INCLUDED;
  const suspensionDaysLimitIncluded =
    appSettings?.SUSPENSION_DAYS_LIMIT_INCLUDED;
  const garageRiskDiscountPercentIncluded =
    appSettings?.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED;

  let suspensionDaysFields = [];
  if (suspensionDaysIncluded) {
    suspensionDaysFields.push(config.suspensionDays);
  }
  let activationDaysFields = [];

  if (activationDaysIncluded) {
    activationDaysFields.push(config.activationDays);
  }

  let suspensionDaysLimitFields = [];
  if (suspensionDaysLimitIncluded) {
    suspensionDaysLimitFields.push(config.suspensionDaysLimit);
  }

  let garageRiskDiscountPercentFields = [];
  if (garageRiskDiscountPercentIncluded) {
    garageRiskDiscountPercentFields.push(config.garageRiskDiscountPercent);
  }

  const { all: insurerPartnerNumber } = useFields([
    config.insurerPartnerNumber,
  ]);
  const { all: insurerBranchOffice } = useFields([config.insurerBranchOffice]);
  const { all: insurerPolicyNumber } = useFields([config.insurerPolicyNumber]);
  const { all: insurerGroupPolicyNumber } = useFields([
    config.insurerGroupPolicyNumber,
  ]);

  const { all: premiums } = useFields([
    config.annualGrossPremium,
    config.annualNetPremium,
  ]);
  const { all: validFromDate } = useFields([config.validFromDate]);
  const { all: validToDate } = useFields([config.validToDate]);
  const { all: validToAndCancelationDocumentDates } = useFields([
    config.validToDate,
    config.cancelationDocumentDate,
  ]);
  const { all: expiryDate } = useFields([config.expiryDate]);
  const { all: greenCardExpiryDate } = useFields([config.greenCardExpiryDate]);
  const { all: payments } = useFields([
    config.paymentMethod,
    config.paymentMean,
  ]);
  const { all: paymentMethod } = useFields([config.paymentMethod]);
  const { all: actSuspDays } = useFields([
    ...suspensionDaysFields,
    ...activationDaysFields,
  ]);
  const { all: suspLimit } = useFields([...suspensionDaysLimitFields]);
  const { all: garageRiskDiscountPercent } = useFields([
    ...garageRiskDiscountPercentFields,
  ]);
  const { all: reasonForModificationComment } = useFields([
    config.reasonForModificationComment,
  ]);
  const { all: motorTaxExclusionReason } = useFields([
    config.motorTaxExclusionReason,
  ]);

  const { all: carUsageCode } = useFields([config.carUsageCode]);
  const { all: co2Emission } = useFields([config.co2Emission]);
  const { all: cubicCapacityCcm } = useFields([config.cubicCapacityCcm]);
  const { all: firstRegistrationDate } = useFields([
    config.firstRegistrationDate,
  ]);
  const { all: maxGrossWeightKg } = useFields([config.maxGrossWeightKg]);
  const { all: powerKw } = useFields([config.powerKw]);
  const { all: vehiclePowerTypeCode } = useFields([
    config.vehiclePowerTypeCode,
  ]);

  const { all: emptyWeightKg } = useFields([config.emptyWeightKg]);

  const { all: isPluginHybrid } = useFields([config.isPluginHybrid]);

  const { all: vehicleTypeCode } = useFields([config.vehicleTypeCode]);
  const { all: isPolicyIssued } = useFields([config.isPolicyIssued]);
  const { all: dueDate } = useFields([config.dueDate]);
  const { all: documentDate } = useFields([config.documentDate]);
  const { all: cancelationDocumentDate } = useFields([
    config.cancelationDocumentDate,
  ]);

  const { all: hullDeductibleFields } = useFields([
    ...(appSettings.SHOW_HULL_PRODUCT ? [config.hullProduct] : []),
    config.gap,
    config.replacementValue,
    config.grossNegligence,
    config.electroPlusPackage,
    config.hullDeductiblePercent,
    config.hullDeductibleMinAmount,
    config.hullDeductibleMaxAmount,
    config.hullDeductibleComment,
  ]);

  let left = <></>;
  let right = <></>;
  let bottom = <></>;

  const validToDateOrValidToAndCancelationDocumentDates = item.associatedObject
    .validToDate
    ? premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
      ? validToAndCancelationDocumentDates
      : validToDate
    : null;

  if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      left = (
        <>
          {dueDate}
          {insurerPartnerNumber}
          {insurerBranchOffice}
          {insurerPolicyNumber}
          {appSettings?.INCLUDE_GROUP_POLICY ? insurerGroupPolicyNumber : null}
          {isPolicyIssued}
          {documentDate}
          {premiums}
        </>
      );
      right = (
        <>
          {validFromDate}
          {expiryDate}
          {appSettings?.HAS_GREENCARD_EXPIRY_DATE &&
          premiumTypeKey === PremiumType.MTPL
            ? greenCardExpiryDate
            : null}

          {validToDateOrValidToAndCancelationDocumentDates}

          {payments}
          {actSuspDays}
          {suspLimit}
        </>
      );
      bottom = <>{reasonForModificationComment}</>;
    }
    const isComplexEnginePowerDialog =
      premiumTypeKey === PremiumType.HORSE_POWER &&
      !item.extraData.isInfinitePremium;

    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      left = <>{validFromDate}</>;
      right = (
        <>
          {motorTaxExclusionReason}
          {paymentMethod}
          {suspLimit}
        </>
      );
      // bottom = <>{suspLimit}</>;
    }
    let horsePowerLeft = null;
    let horsePowerRight = null;
    if (
      premiumTypeKey === PremiumType.HORSE_POWER &&
      isComplexEnginePowerDialog
    ) {
      horsePowerLeft = (
        <>
          {carUsageCode}
          {co2Emission}
          {cubicCapacityCcm}
          {firstRegistrationDate}
        </>
      );
      horsePowerRight = (
        <>
          {vehiclePowerTypeCode}
          {vehicleTypeCode}
          {maxGrossWeightKg}
          {powerKw}
          {emptyWeightKg}
          {isPluginHybrid}
        </>
      );
    }
    if (horsePowerLeft && horsePowerRight) {
      return (
        <>
          <div className="row">
            <div className="column">
              {left}
              {right}
            </div>
            <>
              <div
                className="column"
                style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
              ></div>
              <div className="column">
                {horsePowerLeft}
                {horsePowerRight}
              </div>
            </>
          </div>
          <div className="row">{bottom}</div>
        </>
      );
    }

    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      left = <>{premiums}</>;
      right = (
        <>
          {validFromDate}
          {validToDateOrValidToAndCancelationDocumentDates}
          {suspLimit}
        </>
      );
      // bottom = <>{suspLimit}</>;
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
    ) {
      left = (
        <>
          {dueDate}
          {insurerPartnerNumber}
          {insurerBranchOffice}
          {insurerPolicyNumber}
          {appSettings?.INCLUDE_GROUP_POLICY ? insurerGroupPolicyNumber : null}
          {isPolicyIssued}
          {documentDate}
          {premiums}
        </>
      );
      right = (
        <>
          {validFromDate}
          {expiryDate}
          {validToDateOrValidToAndCancelationDocumentDates}
          {payments}
          {actSuspDays}
          {suspLimit}
          {garageRiskDiscountPercent}
        </>
      );
      bottom = <>{reasonForModificationComment}</>;
      const dialogContents = (
        <>
          <div className="row">
            <div className="column">{left}</div>
            <div className="column">{right}</div>
            <>
              <div
                className="column"
                style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
              ></div>
              <div className="column">{hullDeductibleFields}</div>
            </>
          </div>
          <div className="row">{bottom}</div>
        </>
      );
      return dialogContents;
    }
  }
  if (dialogType === TimelineDialogType.ADJUST_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      left = (
        <>
          {dueDate}
          {isPolicyIssued}
          {documentDate}
          {premiums}
          {validFromDate}
        </>
      );
      right = (
        <>
          {payments}
          {actSuspDays}
          {suspLimit}
        </>
      );
      bottom = <>{reasonForModificationComment}</>;
    }
    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      left = <>{validFromDate}</>;
      right = (
        <>
          {motorTaxExclusionReason}
          {paymentMethod}
          {suspLimit}
        </>
      );
      bottom = <>{reasonForModificationComment}</>;
    }

    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      left = (
        <>
          {premiums}
          {validFromDate}
        </>
      );
      right = <>{suspLimit}</>;
      bottom = <>{reasonForModificationComment}</>;
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
    ) {
      left = (
        <>
          {dueDate}
          {isPolicyIssued}
          {documentDate}
          {premiums}
          {validFromDate}
        </>
      );
      right = (
        <>
          {payments}
          {actSuspDays}
          {suspLimit}
          {garageRiskDiscountPercent}
        </>
      );
      bottom = <>{reasonForModificationComment}</>;
      const dialogContents = (
        <>
          <div className="row">
            <div className="column">{left}</div>
            <div className="column">{right}</div>
            <>
              <div
                className="column"
                style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
              ></div>
              <div className="column">{hullDeductibleFields}</div>
            </>
          </div>
          <div className="row">{bottom}</div>
        </>
      );
      return dialogContents;
    }
  }

  if (dialogType === TimelineDialogType.CONTINUE_PREMIUM) {
    left = <></>;
    right = <></>;
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.CANCEL_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      left = <>{validFromDate}</>;
      right = <>{cancelationDocumentDate}</>;
      bottom = <>{reasonForModificationComment}</>;
    }
    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      left = <>{validFromDate}</>;
      right = <>{motorTaxExclusionReason}</>;
      bottom = <>{reasonForModificationComment}</>;
    }

    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      left = <>{validFromDate}</>;
      right = <></>;
      bottom = <>{reasonForModificationComment}</>;
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
    ) {
      left = <>{validFromDate}</>;
      right = <>{cancelationDocumentDate}</>;
      bottom = <>{reasonForModificationComment}</>;
    }
    //debugger
    if (
      premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE ||
      premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA
    ) {
      left = <>{validFromDate}</>;
      right = <>{cancelationDocumentDate}</>;
      bottom = <>{reasonForModificationComment}</>;
    }
  }
  if (dialogType === TimelineDialogType.DELETE_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      left = <></>;
      right = <></>;
      bottom = <>{reasonForModificationComment}</>;
    }
    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      left = <></>;
      right = <></>;
      bottom = <>{reasonForModificationComment}</>;
    }

    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      left = <></>;
      right = <></>;
      bottom = <>{reasonForModificationComment}</>;
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
    ) {
      left = <></>;
      right = <></>;
      bottom = <>{reasonForModificationComment}</>;
    }
    //debugger
    if (
      premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE ||
      premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA
    ) {
      left = <></>;
      right = <></>;
      bottom = <>{reasonForModificationComment}</>;
    }
  }
  let dialogContents = (
    <>
      <div className="row">
        <div className="column">{left}</div>
        <div className="column">{right}</div>
      </div>
      <div className="row">{bottom}</div>
    </>
  );

  //debugger
  if (
    (premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE ||
      premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA) &&
    (dialogType === TimelineDialogType.ADD_PREMIUM ||
      dialogType === TimelineDialogType.ADJUST_PREMIUM ||
      dialogType === TimelineDialogType.CORRECT_PREMIUM)
  ) {
    return <FleetLegalFormContent />;
  }

  // const formik = useFormikContext();
  // const annualGrossPremium = stringToNumber(
  //   formik.values["annualGrossPremium"]
  //     ? formik.values["annualGrossPremium"]
  //     : 0
  // );

  // const annualNetPremiumState = formik.values["annualNetPremium"];

  // useEffect(() => {
  //   const annualNetPremium =
  //     Math.round(((annualGrossPremium / 111) * 100 + Number.EPSILON) * 100) /
  //     100;
  //   const annualNetPremimString = annualNetPremium.toLocaleString("de-DE", {
  //     minimumFractionDigits: 0,
  //     maximumFractionDigits: 2,
  //   });
  //   formik.setFieldValue("annualNetPremium", annualNetPremimString);
  // }, [annualGrossPremium, annualNetPremiumState]);

  return dialogContents;
};
