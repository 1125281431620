import { ExcelExport } from "@progress/kendo-react-excel-export";
import AppSettings from "AppSettings";
import { ApplicationReportDialogComponent } from "components/ApplicationReportDialogComponent";
import { ChangeVehicleIssuingStatusDialogComponent } from "components/ChangeVehicleIssuingStatusDialogComponent/ChangeVehicleIssuingStatusDialogComponent";
import { ChangeVehiclePendingIssuingStatusDialogComponent } from "components/ChangeVehiclePendingIssuingStatusDialogComponent/ChangeVehiclePendingIssuingStatusDialogComponent";
import { useCurrentRoute } from "config/routes";
import { constants } from "library/constants";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  fleetFormSlice,
  loadFleetWithInsurersActions,
  setFleetAppSettings,
} from "store/fleetForm";
import {
  selectAllFleetPageColumns,
  selectAllFleetPageTempColumns,
  selectColumnsTable,
  setFleetPageColumns,
  setFleetPageTempColumns,
} from "store/fleetPageColumnOptions";
import { ExportExcelVehicles } from "../../components/ExportExcelVehicles";
import {
  fleetPageSlice,
  loadVehiclesActions,
  selectAllVehicles,
  selectDefaultColumns,
  setSelectedViewIdFleet,
} from "../../store/fleetPage";

import { selectColumnOptionsSettings } from "store/settings";
import { ExpandedColumn } from "types/columns";
import { hydrateColumn } from "utils/columns";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { sanitizeFileName } from "../../utils/utils";
import { CancelStatusDialog } from "./CancelStatusDialog";
import { ChangeVehicleStatusDialog } from "./ChangeVehicleStatusDialog";
import { DeleteDialog } from "./DeleteDialog";
import { DeleteTEMPDialog } from "./DeleteTEMPDialog";
import { DuplicateWithPremiumsDialog } from "./DuplicateWithPremiumsDialog";
import FleetListToolbar from "./FleetListToolbar";
import VehicleListTable from "./VehicleListTable/VehicleListTable";
import { defaultColumnsByKey } from "./VehicleListTable/columns";
import { Navigation } from "./navigation/Navigation";

export const Fleet = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const route = useCurrentRoute();
  const isFleetRoute = route === constants.fleet;
  const vehicles = useSelector(selectAllVehicles);
  const [clearAllSelectedVehicles, setClearAllSelectedVehicles] =
    useState(false);
  const columnOptionsSettings = useSelector(selectColumnOptionsSettings);

  const detailsListColumnsRedux = useSelector(selectColumnsTable)(t);
  const refExcelExport = useRef<ExcelExport>();
  const isChangeStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehicleStatusDialog.isOpen
  );
  const isChangeIssuingStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehicleIssuingStatusDialog.isOpen
  );
  const isChangePendingIssuingStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehiclePendingIssuingStatusDialog.isOpen
  );
  const isCancelStatusDialogOpen = useSelector(
    (s) => s.fleetPage.cancelVehicleStatusDialog.isOpen
  );

  const taxonomy = useSelector((s) => s.taxonomy);
  const isApplicationReportDialogOpen = useSelector(
    (s) => s.fleetPage.applicationReportDialog.isOpen
  );
  const fleetName = useSelector((s) => s.fleetPage.fleet?.fleetName);
  const fleetCountryCode = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.countryCode
  );
  const fleetNameFromForm = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.fleetName
  );
  const selectedViewId = useSelector((s) => s.fleetPage.selectedViewId);
  const isDuplicateVehicleWithPremiumsDialogOpen = useSelector(
    (s) => s.fleetPage.duplicateVehicleWithPremiumsDialog.isOpen
  );
  const columns = useSelector(selectAllFleetPageColumns);

  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const columnsCountryMap = useSelector(
    (s) => s.fleetPageColumnOptions.columnsCountryMap
  );
  const defaultColumns = useSelector(selectDefaultColumns) as any;
  const fleetPageTempColumns = useSelector(selectAllFleetPageTempColumns);

  const country = useSelector((s) => s.fleetPageColumnOptions.country);
  const fileName = useMemo(() => {
    return sanitizeFileName(
      "Vehicles_" +
        (fleetNameFromForm ?? "List") +
        moment(new Date()).format("_YYYY_MM_DD_HH_mm_ss")
    );
  }, [fleetNameFromForm]);

  useEffect(() => {
    return () => {
      dispatch(fleetPageSlice.actions.resetState());
      dispatch(fleetFormSlice.actions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!fleetName && !fleetNameFromForm) {
      dispatch(
        loadFleetWithInsurersActions.trigger({ fleetId, getById: true })
      );
    }
  }, [fleetId, fleetName, fleetNameFromForm]);

  useEffect(() => {
    if (!fleetCountryCode) return;
    dispatch(
      setFleetAppSettings(
        AppSettings.getInstance().getAppSettingsTypes()[
          taxonomy.Country.byId[fleetCountryCode].code + ""
        ]
      )
    );
  }, [fleetCountryCode, taxonomy]);

  useEffect(() => {
    if (!selectedViewId) return;
    const columnOption = columnOptionsSettings.find(
      (item) => item.userAppSettingId === selectedViewId
    );
    let defaultViewColumns = JSON.parse(
      columnOption.userAppSettingValue
    ) as ExpandedColumn[];

    let finalColumns: ExpandedColumn[] = defaultViewColumns.filter((el) =>
      fleetAppSettings?.DEFAULT_COLUMNS.includes(el.key)
    );
    finalColumns = finalColumns.map((c) => {
      if (c.key === "hullProduct") {
        return {
          ...c,
          filter: defaultColumnsByKey[c.key].filter,
        };
      } else {
        return c;
      }
    });
    fleetAppSettings?.DEFAULT_COLUMNS.map((defaultColumn) => {
      const columnFromView = defaultViewColumns.find(
        (c) => c.key === defaultColumn
      );
      if (!columnFromView) {
        //   finalColumns.push(columnFromView);
        // } else {
        finalColumns.push(defaultColumnsByKey[defaultColumn]);
      }
    });

    const mappedColumns = finalColumns?.reduce((arr, c) => {
      const hydratedColumn = hydrateColumn({
        column: c,
        defaultColumns: defaultColumns,
      });
      if (!hydratedColumn) return arr;
      const tempColumn = fleetPageTempColumns?.find((el) => el.key === c.key);
      if (tempColumn && tempColumn?.filter) {
        hydratedColumn.filter = tempColumn.filter;
      }
      const column = columns?.find((el) => el.key === c.key);
      if (column && column?.filter) {
        hydratedColumn.filter = column.filter;
      }

      arr.push(hydratedColumn);
      return arr;
    }, []);

    dispatch(setFleetPageTempColumns(mappedColumns));
  }, [selectedViewId, fleetAppSettings, columnOptionsSettings]);

  useEffect(() => {
    if (!fleetAppSettings) return;

    if (
      !columnsCountryMap[fleetAppSettings.COUNTRY_CODE] ||
      fleetAppSettings.COUNTRY_CODE !== country
    ) {
      const defaultColumnOption = columnOptionsSettings.find(
        (item) => item.isDefault
      );
      if (defaultColumnOption) {
        // If there is default column option set, use it's columns
        dispatch(setSelectedViewIdFleet(defaultColumnOption.userAppSettingId));
        let defaultViewColumns = JSON.parse(
          defaultColumnOption.userAppSettingValue
        ) as ExpandedColumn[];

        let finalColumns: ExpandedColumn[] = defaultViewColumns.filter((el) =>
          fleetAppSettings?.DEFAULT_COLUMNS.includes(el.key)
        );
        finalColumns = finalColumns.map((c) => {
          if (c.key === "hullProduct") {
            return {
              ...c,
              filter: defaultColumnsByKey[c.key].filter,
            };
          } else {
            return c;
          }
        });
        fleetAppSettings?.DEFAULT_COLUMNS.map((defaultColumn) => {
          const columnFromView = defaultViewColumns.find(
            (c) => c.key === defaultColumn
          );
          if (!columnFromView) {
            //   finalColumns.push(columnFromView);
            // } else {
            finalColumns.push(defaultColumnsByKey[defaultColumn]);
          }
        });

        const mappedColumns = finalColumns?.reduce((arr, c) => {
          const hydratedColumn = hydrateColumn({
            column: c,
            defaultColumns: defaultColumns,
          });
          if (!hydratedColumn) return arr;
          arr.push(hydratedColumn);
          return arr;
        }, []);

        dispatch(
          setFleetPageColumns({
            columns: mappedColumns,
            country: fleetAppSettings?.COUNTRY_CODE,
          })
        );
      } else {
        dispatch(
          setFleetPageColumns({
            columns: fleetAppSettings?.DEFAULT_COLUMNS.map(
              (column) => defaultColumnsByKey[column]
            ),
            country: fleetAppSettings?.COUNTRY_CODE,
          })
        );
      }
    }
  }, [fleetAppSettings, country]);

  useEffect(() => {
    if (!columns) return;
    dispatch(
      loadVehiclesActions.trigger({
        isReset: true,
        shouldDelay: false,
      })
    );
  }, [fleetId, columns]);

  const detailsListColumns = useMemo(() => {
    return detailsListColumnsRedux
      ?.map((column) => {
        if (column?.key === "insuranceLineIcons") {
          return {
            ...column,
            minWidth: 320,
            maxWidth: 400,
          };
        }
        return column;
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
  }, [detailsListColumnsRedux]);

  const columnsToPassToExcel = useMemo(() => {
    return columns?.filter((c) => c.isVisible && c.key !== "fleetName");
  }, [columns]);

  return (
    <>
      <Navigation refExcelExport={refExcelExport?.current} />
      <FleetListToolbar
        setClearAllSelectedVehicles={setClearAllSelectedVehicles}
      />
      <VehicleListTable
        items={vehicles}
        columns={detailsListColumns}
        setClearAllSelectedVehicles={setClearAllSelectedVehicles}
        clearAllSelectedVehicles={clearAllSelectedVehicles}
      />
      {isFleetRoute && <DeleteTEMPDialog />}
      {isFleetRoute && <DeleteDialog />}
      {isChangeIssuingStatusDialogOpen && isFleetRoute && (
        <ChangeVehicleIssuingStatusDialogComponent />
      )}
      {isChangePendingIssuingStatusDialogOpen && isFleetRoute && (
        <ChangeVehiclePendingIssuingStatusDialogComponent />
      )}
      {isChangeStatusDialogOpen && isFleetRoute && (
        <ChangeVehicleStatusDialog />
      )}
      {isDuplicateVehicleWithPremiumsDialogOpen && (
        <DuplicateWithPremiumsDialog />
      )}
      {isCancelStatusDialogOpen && isFleetRoute && <CancelStatusDialog />}

      {/* {isProrataReportDialogOpen && isFleetRoute && <ProrataReportDialog />} */}
      {isApplicationReportDialogOpen && isFleetRoute && (
        <ApplicationReportDialogComponent />
      )}

      <ExportExcelVehicles
        ref={refExcelExport}
        columns={columnsToPassToExcel?.filter((col) => col.key !== "filler")}
        fileName={fileName}
      />
    </>
  );
};
