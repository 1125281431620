import { useMutation } from "react-query";
import {
  IndexRequestParams,
  updateFleetPremiumDocumentDate,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const updateFleetPremiumDocumentDateFn = async (params: IndexRequestParams) => {
  return await updateFleetPremiumDocumentDate(params);
};

export function useUpdateFleetPremiumDocumentDate() {
  return useMutation(updateFleetPremiumDocumentDateFn, {
    mutationKey: MUTATION_KEYS.updateFleetPremiumDocumentDate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
