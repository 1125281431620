import ComboBoxField from "components/controls/ComboBoxField";
import React, { useMemo } from "react";
import { useSelector } from "store/hooks";
import { PremiumType } from "types/types";

const group1 = [
  "index",
  "remove",
  "continue",
  "update",
  "adjustment",
  "paymentMethodChange",
  "updateFleetPremiumDocumentDate",
];

const FormActionInsurerField = ({
  allInsurerOptions,
  values,
  errors,
  setFieldValue,
  actionFormType,
  required = true,
}) => {
  const taxonomy = useSelector((s) => s.taxonomy);

  const fleetWithPremiums = useSelector((s) => s.fleetForm.fleetWithPremiums);

  let insurerOptions = useMemo(() => {
    const formPremiumType = values.premiumType?.value;
    const taxonomyByCode = taxonomy.PremiumType.byCode;

    const isMtplPremiumType =
      formPremiumType === taxonomyByCode[PremiumType.MTPL]?.id;

    const isAllHullPremiumType =
      formPremiumType === taxonomyByCode[PremiumType.HULL]?.id ||
      formPremiumType === taxonomyByCode[PremiumType.HULL_BASIC]?.id;

    const isAllFleetLegalPremiumType =
      formPremiumType === taxonomyByCode[PremiumType.FLEETLEGAL_PRORATA]?.id ||
      formPremiumType ===
        taxonomyByCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

    if (actionFormType === "addPremium") {
      if (isMtplPremiumType || isAllFleetLegalPremiumType) {
        return allInsurerOptions;
      }

      const mtplInsurensInfo = fleetWithPremiums?.mtplInsurersInfo;
      return allInsurerOptions.filter((item) =>
        mtplInsurensInfo?.find(
          (infoItem) => infoItem.insurerInternalNumber === item.value
        )
      );
    }
    if (actionFormType === "updateFleetPremiumForEngineTax20250401") {
      const mtplInsurensInfo = fleetWithPremiums?.mtplInsurersInfo;
      return allInsurerOptions.filter((item) =>
        mtplInsurensInfo?.find(
          (infoItem) => infoItem.insurerInternalNumber === item.value
        )
      );
    }

    if (group1.includes(actionFormType)) {
      if (isAllHullPremiumType) {
        const hullInsurensInfo = fleetWithPremiums?.hullInsurersInfo;
        return allInsurerOptions.filter((item) =>
          hullInsurensInfo.find(
            (infoItem) => infoItem.insurerInternalNumber === item.value
          )
        );
      } else if (isAllFleetLegalPremiumType) {
        const fleetLegalInsurersInfo =
          fleetWithPremiums?.fleetLegalInsurersInfo;

        return allInsurerOptions.filter((item) =>
          fleetLegalInsurersInfo.find(
            (infoItem) => infoItem.insurerInternalNumber === item.value
          )
        );
      } else {
        const mtplInsurensInfo = fleetWithPremiums?.mtplInsurersInfo;
        return allInsurerOptions.filter((item) =>
          mtplInsurensInfo.find(
            (infoItem) => infoItem.insurerInternalNumber === item.value
          )
        );
      }
    }

    // if (actionFormType === "addPremium") {
    //   const mtplInsurensInfo = fleetWithPremiums?.mtplInsurersInfo;
    //   return allInsurerOptions.filter((item) =>
    //     mtplInsurensInfo?.find(
    //       (infoItem) => infoItem.insurerInternalNumber === item.value
    //     )
    //   );
    // }

    if (actionFormType === "adjustDeductible") {
      const hullInsurensInfo = fleetWithPremiums?.hullInsurersInfo;
      return allInsurerOptions.filter((item) =>
        hullInsurensInfo.find(
          (infoItem) => infoItem.insurerInternalNumber === item.value
        )
      );
    }
  }, [
    actionFormType,
    allInsurerOptions,
    fleetWithPremiums?.hullInsurersInfo,
    fleetWithPremiums?.mtplInsurersInfo,
    fleetWithPremiums?.fleetLegalInsurersInfo,
    values.premiumType,
    taxonomy,
  ]);

  return (
    <ComboBoxField
      disabled={false}
      errors={errors}
      required={required}
      name="insurerPartnerNumber"
      label={"bfm.insurer.label"}
      setFieldValue={setFieldValue}
      options={insurerOptions || []}
      defaultValue={values?.insurerPartnerNumber}
      confidentiality={null}
    />
  );
};

export default FormActionInsurerField;
