import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { calculateAnnualNetPremium } from "utils/utils";

const formatForDoubleFieldPercent11 = (value) => {
  const annualNetPremium = Number(
    Number(value?.replace(".", "")?.replace(",", ".")) / 1.11
  )
    .toFixed(2)
    ?.toString()
    ?.replaceAll(".", ",");
  return annualNetPremium;
};

const formatForDoubleFieldPercent4 = (value) => {
  const annualNetPremium = Number(
    Number(value?.replace(".", "")?.replace(",", ".")) / 1.04
  )
    .toFixed(2)
    ?.toString()
    ?.replaceAll(".", ",");
  return annualNetPremium;
};

const AnnualFields = ({
  premiumTypeSelected,
  fieldItemStyle,
  errors,
  setFieldValue,
  handleChange,
  values,
}) => {
  const formik = useFormikContext();

  const { t } = useTranslation();
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const insurerSettings = useSelector((s) => s.fleetForm.insurerSettings);

  const formatForDoubleFieldPercentHungarian = (defaultValueOperation) => {
    let annualNetPremium = "";
    if (defaultValueOperation.formValues) {
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;
      if (!insurer) {
        return null;
      }
      const premiumTypeCode = formValues.premiumType?.value;

      const insurerSettingsForInsurer = insurerSettings?.filter(
        (is) => is.insurerPartnerNumber === insurer
      );

      let insurerSetting = null;
      if (insurerSettingsForInsurer.length === 1) {
        insurerSetting = insurerSettingsForInsurer?.find((is) => {
          const a = moment(
            moment(is.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          const b = moment(
            moment(formValues.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          if (
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length > 0
          ) {
            return true;
          }
          return a <= b;
        });
      } else if (insurerSettingsForInsurer.length > 1) {
        // Find latest insurer setting with valid from date before dialog date. Sording the array by validFromDate descending
        insurerSetting = insurerSettingsForInsurer
          ?.sort((a, b) => {
            return (
              moment(b.validFromDate).toDate().getTime() -
              moment(a.validFromDate).toDate().getTime()
            );
          })
          ?.find((is) => {
            const a = moment(
              moment(is.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            const b = moment(
              moment(formValues.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            if (
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length >
                0
            ) {
              return true;
            }
            return a <= b;
          });
      }

      if (insurerSetting) {
        if (
          insurerSetting.insurerSettingPerPremiumTypeDetailsHU &&
          insurerSetting.insurerSettingPerPremiumTypeDetailsHU.length > 0
        ) {
          const setting3 =
            insurerSetting.insurerSettingPerPremiumTypeDetailsHU.find(
              (el) => el.premiumTypeCode === premiumTypeCode
            );

          const annualGrossPremium = parseFloat(
            (formValues.annualGrossPremium as string)
              ?.replaceAll(" ", "")
              ?.replaceAll(".", "")
              .replaceAll(",", ".")
          );
          const maxValueForTaxCalculation = setting3?.maxValueForTaxCalculation;
          const taxPercent = setting3?.taxPercent;
          const taxExtraPercent1 = setting3?.taxExtraPercent1;
          let calculatedPremium = calculateAnnualNetPremium(
            premiumTypeCode,
            taxPercent,
            taxExtraPercent1,
            annualGrossPremium,
            maxValueForTaxCalculation
          );

          //   annualGrossPremium > maxValueForTaxCalculation
          //     ? maxValueForTaxCalculation
          //     : annualGrossPremium;
          // calculatedPremium = calculatedPremium * (1 + taxPercent / 100);
          // calculatedPremium =
          //   calculatedPremium * (1 + taxExtraPercent1 / 100);
          // calculatedPremium =
          //   calculatedPremium * (1 + taxExtraPercent2 / 100);

          const premiumString = calculatedPremium.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          annualNetPremium = premiumString;

          //toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
        }
        // if results attribute value is "undefined" then remove it from the object
        if (annualNetPremium === "undefined") {
          return null;
        }
      }
    }
    return annualNetPremium;
  };

  const calculatedAnnualGrossPremium = useCallback(() => {
    if (!premiumTypeSelected) return "";

    if (["Country.AT"].includes(fleetAppSettings?.COUNTRY_CODE)) {
      if (premiumTypeSelected?.code === "ACCIDENT") {
        if (values.annualGrossPremium) {
          const annualNetPremium = formatForDoubleFieldPercent4(
            values.annualGrossPremium
          );
          setFieldValue("annualNetPremium", annualNetPremium);
        }
      } else {
        if (values.annualGrossPremium) {
          const annualNetPremium = formatForDoubleFieldPercent11(
            values.annualGrossPremium
          );
          setFieldValue("annualNetPremium", annualNetPremium);
        }
      }
    } else {
      const annualNetPremium = formatForDoubleFieldPercentHungarian({
        formValues: formik.values,
      });
      if (formik.dirty && !formik.isSubmitting) {
        setFieldValue("annualNetPremium", annualNetPremium);
      }
    }
    return "";
  }, [
    premiumTypeSelected,
    setFieldValue,
    values.annualGrossPremium,
    fleetAppSettings,
  ]);

  useEffect(() => {
    calculatedAnnualGrossPremium();
  }, [calculatedAnnualGrossPremium]);

  return (
    <>
      <div className={fieldItemStyle}>
        <DoubleNumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.annualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"annualGrossPremium"}
          disabled={false}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          defaultValueFunction={(value) => value}
        />
      </div>
      <div className={fieldItemStyle}>
        <DoubleNumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.annualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"annualNetPremium"}
          disabled={false}
          insurerSettingsFunction={(value) => value}
          label={"bfm.vehicleForm.annualNetPremium.label"}
        />
      </div>
    </>
  );
};

export default AnnualFields;
