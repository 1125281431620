import AppSettings, { InsurerSettingsTemplate } from "AppSettings";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const InsurerSettingsCell = ({ row }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const insurerCountrySettings =
    AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[row?.countryCodeId]?.code
    ];

  return (
    <>
      {row.insurerSettingsHistory?.length > 0 ? (
        <>
          {insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
            InsurerSettingsTemplate.SUSPENDED_DAYS_LIMITS
          ) && insurerCountrySettings.SUSPENSION_DAYS_INCLUDED ? (
            <>
              {t("bfm.vehicleForm.suspensionDaysLimit.label")}
              {"; "}
            </>
          ) : null}
          {!["Country.AT"].includes(insurerCountrySettings?.COUNTRY_CODE) ? (
            <>
              {t("bfm.vehicleForm.insuranceLineDetails.label")}
              {"; "}
            </>
          ) : null}
          {insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
            InsurerSettingsTemplate.GARAGE_RISK_DISCOUNT
          ) && insurerCountrySettings.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED ? (
            <>
              {t("bfm.garageRiskDiscountPercentHullHullBasic.label")}
              {": "}
              <b>
                {row.insurerSettingsHistory[0]?.garageRiskDiscountPercentHull}/
                {
                  row.insurerSettingsHistory[0]
                    ?.garageRiskDiscountPercentHullBasic
                }
              </b>
              {"; "}
            </>
          ) : null}
          {insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
            InsurerSettingsTemplate.SUSPENSION_AND_ACTIVATION_DAYS
          ) &&
          insurerCountrySettings.SUSPENSION_DAYS_INCLUDED &&
          insurerCountrySettings.ACTIVATION_DAYS_INCLUDED ? (
            <>
              {t("bfm.suspensionActivationDays")}
              {": "}
              <b>
                {row.insurerSettingsHistory[0]?.suspensionDays}/
                {row.insurerSettingsHistory[0]?.activationDays}
              </b>
              {"; "}
            </>
          ) : null}
        </>
      ) : (
        <>-</>
      )}
    </>
  );
};

export default InsurerSettingsCell;
