import { DefaultButton, DialogFooter, mergeStyleSets } from "@fluentui/react";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import TextInputField from "components/controls/TextInputField";
import { Formik } from "formik";
import { useUpdateFleetPremiumForEngineTax20250401 } from "hooks/data/mutations/useUpdateFleetPremiumForEngineTax20250401";
import { delay } from "library/delay";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { setDialog, setFleetWithPremiums } from "../../../store/fleetForm";
import useActionConfig from "../hooks/useActionConfg";
import DocumentDateDependencyField from "./components/DocumentDateDependencyField";
import FormActionInsurerField from "./components/FormActionInsurerField";

const UpdateFleetPremiumForEngineTax20250401Dialog = () => {
  const { t } = useTranslation();
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const actionsConfig = useActionConfig(fleetAppSettings);
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  const { mutate: onUpdateFleetPremiumForEngineTax2025040, isLoading } =
    useUpdateFleetPremiumForEngineTax20250401();
  const mtplGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mtplGroupPolicyInfo
  );
  const hullGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.hullGroupPolicyInfo
  );
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const premiumTypeOptions = actionsConfig.premiumTypeOptions;
  const vehiclePolicyStatusOptions = actionsConfig.vehiclePolicyStatusOptions;

  const insurerOptions = actionsConfig.insurerOptions;
  const initialValues = {
    premiumType: {
      id: 6,
      code: "HORSE_POWER",
    },
    insurerPartnerNumber: null,
    validFromDate: new Date("2025-04-01"),
    documentDate: null,
    isPolicyIssued: false,
    reasonForModificationComment: null,
    insurerGroupPolicyNumber: null,
    policyStatus: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      ...(fleetAppSettings.INCLUDE_GROUP_POLICY && {
        insurerGroupPolicyNumber: yup.mixed().nullable(),
      }),
      premiumType: yup.mixed().required(),
      insurerPartnerNumber: yup.mixed(),
      validFromDate: yup.mixed().required(),
      documentDate: yup.mixed().nullable(),
      isPolicyIssued: yup.mixed().nullable(),
      reasonForModificationComment: yup.string().max(512).nullable(),
    });
  }, [t, fleetAppSettings]);
  const classes = getClassNames();

  const onSubmit = (values) => {
    let body: any = {
      premiumType: 6,
      insurerPartnerNumber: values?.insurerPartnerNumber
        ? values?.insurerPartnerNumber?.value
        : null,

      validFromDate: moment(values?.validFromDate).format("YYYY-MM-DD"),
      documentDate: values?.documentDate
        ? moment(values?.documentDate).format("YYYY-MM-DD")
        : null,
      reasonForModificationComment: values?.reasonForModificationComment,
      isPolicyIssued: values?.isPolicyIssued,
      ...((fleetAppSettings.DEFAULT_COLUMNS.includes("mtplPolicyStatus") ||
        fleetAppSettings.DEFAULT_COLUMNS.includes("hullPolicyStatus")) && {
        policyStatus: values?.policyStatus?.value,
      }),
    };

    onUpdateFleetPremiumForEngineTax2025040(
      {
        fleetId,
        body: {
          ...body,
        },
      },
      {
        onSuccess: (res) => {
          dispatch(setFleetWithPremiums(res?.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { values, handleSubmit, setFieldValue, handleChange, errors } =
          props;
        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                <div className={classes.fieldItem}>
                  <FormActionInsurerField
                    actionFormType={"updateFleetPremiumForEngineTax20250401"}
                    allInsurerOptions={insurerOptions}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    required={false}
                  />
                </div>

                <DocumentDateDependencyField
                  premiumTypeSelected={{ id: 6, code: "HORSE_POWER" }}
                  classesItem={classes.fieldItem}
                  errors={errors}
                  fieldName={"documentDate"}
                  setFieldValue={setFieldValue}
                  values={values}
                />

                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.reasonForModificationComment}
                    onChange={handleChange}
                    errors={errors}
                    name={"reasonForModificationComment"}
                    multiline
                    rows={4}
                    disabled={false}
                    label={"bfm.comment.label"}
                  />
                </div>
                <DialogFooter className={classes.footer}>
                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default UpdateFleetPremiumForEngineTax20250401Dialog;
