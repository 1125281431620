import { DefaultButton, PrimaryButton, mergeStyleSets } from "@fluentui/react";
import AppSettings from "AppSettings";
import { VSpace } from "components/Spacer";
import DatePickerField from "components/controls/DatePickerField";
import { HSpace } from "components/styled";
import { DeleteDialog } from "pages/insurers/DeleteDialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import styled from "styled-components";
import { PremiumType } from "types/types";
import InsuranceLineForm from "./InsuranceLineForm";
import InsuranceLineHistoryItem from "./InsuranceLineHistoryItem";

const InsuranceLineDetails = ({
  props,
  selectedInsurer,
  insurerSettings,
  classes,
  showForm,
  setShowForm,
  refetchInsurerSettings,
}: any) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const insurerCountrySettings =
    AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[selectedInsurer?.countryCodeId]?.code
    ];

  if (!props.values || !insurerCountrySettings) {
    return null;
  }
  return (
    <>
      {!["Country.AT"].includes(insurerCountrySettings?.COUNTRY_CODE) ? (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {showForm ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div style={{ width: "200px" }}>
                  <DatePickerField
                    errors={props.errors}
                    setFieldValue={props.setFieldValue}
                    defaultValue={props.values?.validFromDate}
                    name={"validFromDate"}
                    disabled={false}
                    label={"bfm.insurerSettings.validFrom.label"}
                    placeholder={t("bfm.selectDate")}
                    required
                    confidentiality={null}
                  />
                </div>
                <VSpace height={20} />
                <div style={{ width: "100%" }}>
                  <div className={classes.textColorBold}>
                    {t("bfm.vehicleForm.insuranceLineDetails.label")}
                  </div>
                  <div className={classesTab.root} id="days-tab">
                    <div className={classesTab.days_left}></div>
                    <InsuranceLineForm item={props.values} props={props} />
                  </div>
                </div>
              </div>
              <div
                className={classesTab.root}
                style={{ display: "flex", alignSelf: "self-end" }}
              >
                <DefaultButton
                  iconProps={{
                    iconName: "Cancel",
                  }}
                  onClick={() => {
                    setShowForm(false);
                  }}
                  text={t("bfm.InsurerDefaultSettings.cancel.label")}
                  disabled={false}
                />
                <HSpace width={10} />
                <PrimaryButton
                  iconProps={{
                    iconName: "Save",
                  }}
                  onClick={() => {
                    props.submitForm();
                  }}
                  text={t("bfm.InsurerDefaultSettings.save.label")}
                  disabled={false}
                />
              </div>
            </>
          ) : null}
          <div className={classesTab.root}>
            {!showForm ? (
              <DefaultButton
                iconProps={{
                  iconName: "Add",
                }}
                onClick={() => {
                  props.setValues({
                    garageRiskDiscountPercentHull: "0,00",
                    garageRiskDiscountPercentHullBasic: "0,00",
                    suspensionDays: "0",
                    activationDays: "0",
                    suspensionDaysLimit: [],
                    insurerSettingPerPremiumTypeAndVehicleMainGroupDetails: [],
                    insurerSettingPerPremiumTypeDetailsHU: ![
                      "Country.AT",
                    ].includes(insurerCountrySettings?.COUNTRY_CODE)
                      ? insurerCountrySettings.VEHICLE_PREMIUM_TYPES.filter(
                          (el) =>
                            el === PremiumType.MTPL || el === PremiumType.HULL
                        ).map((el) => ({
                          premiumTypeCode: el,
                          maxValueForTaxCalculation: "0",
                          taxPercent: "0",
                          taxExtraPercent1: "0",
                          // taxExtraPercent2: "0",
                        }))
                      : [],
                    validFromDate: new Date(),
                  });
                  setShowForm(true);
                }}
                text={t("bfm.InsurerDefaultSettings.createNew.label")}
                disabled={false}
              />
            ) : null}
          </div>
          {insurerSettings && insurerSettings.length > 0 ? (
            <>
              <VSpace height={20} />
              <div className={classesTab.root}>
                <div className={classes.textColorBold}>
                  {t("bfm.vehicleForm.insuranceLineDetailsHistory.label")}
                </div>
                <VSpace height={30} />
              </div>

              <div className={classesTab.root} id="days-tab">
                {insurerSettings?.map((item, index) => {
                  return (
                    <InsuranceLineHistoryItem
                      setShowForm={setShowForm}
                      props={props}
                      key={index}
                      item={{
                        ...item,
                        insurerSettingPerPremiumTypeDetailsHU:
                          item.insurerSettingPerPremiumTypeDetailsHU.map(
                            (det) => {
                              return {
                                ...det,
                                premiumTypeCode:
                                  taxonomy.PremiumType.byId[det.premiumTypeCode]
                                    ?.code,
                              };
                            }
                          ),
                      }}
                      refetchInsurerSettings={refetchInsurerSettings}
                    />
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      <DeleteDialog />
    </>
  );
};

export default InsuranceLineDetails;

export const Table = styled.table`
  border-collapse: collapse;
  // border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  margin-bottom: 20px;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    // border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  tr {
    border-bottom: 1px solid rgb(237, 235, 233);
  }
`;

const classesTab = mergeStyleSets({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px",
  },
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "25%",
    },
  },
  days_left: {
    padding: "14px 24px 0px",
    width: "8%",
    "@media(min-width: 1500px)": {
      width: "8%",
    },
  },
  days: {
    padding: "0px 10px",
    width: "13%",
    "@media(min-width: 1500px)": {
      width: "13%",
    },
  },
  uploadFile: {
    width: "100%",
  },
});
