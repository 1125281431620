import { Icon, TooltipOverflowMode } from "@fluentui/react";
import React from "react";
import FluentTooltip from "../../../components/FluentTooltip";
import { Truncate } from "../../../components/styled";
import { accent } from "../../../config/colors";
import { formatNumber } from "../../../utils/number";
import {
  formatDateString,
  formatDateStringWithTime,
} from "../../../utils/utils";
import CodeCell from "../../fleets/FleetList/columnCells/CodeCell";
import StatusCell from "../StatusCell/StatusCell";
import CheckColumn from "./columnCells/CheckColumn";
import ColumnHoverCard from "./columnCells/ColumnHoverCard";
import ExcludeHorsePowerBasedTaxReason from "./columnCells/ExcludeHorsePowerBasedTaxReason";
import InsuranceLineIconsCell from "./columnCells/InsuranceLineIconsCell";
import LicensePlateCell from "./columnCells/LicensePlateCell/LicensePlateCell";
import LineInsurer from "./columnCells/LineInsurer";
import VehicleVin from "./columnCells/VehicleVin";

export const defaultColumns: any[] = [
  {
    key: "vehicleStatusCode",
    isResizable: true,
    fieldName: "vehicleStatusCode",
    labelKey: "bfm.vehicleStatus.label",
    name: "bfm.vehicleStatus.label",
    maxWidth: 100,
    minWidth: 100,
    isVisible: true,
    isSortable: true,
    isSorted: false,
    isSortedDescending: true,

    isFixed: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehicleStatusCode",
      value: [],
      exclude: [
        "VehicleStatusCode.ALL",
        "VehicleStatusCode.NOT_DEACTIVATED_OR_REFUSED",
      ],
      renderLabel: ({ taxonomy, t }) => {
        const currentTheme = localStorage.getItem("darkMode");
        let color;

        if (currentTheme === "true") {
          color = "white";
        } else {
          color = "black";
        }
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      width: 150,
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },

    onRender: (row) => {
      if (!row) return;
      return <StatusCell vehicle={row} />;
    },
  },
  {
    key: "licensePlate",
    isResizable: true,
    minWidth: 220,
    maxWidth: 220,
    isRowHeader: true,
    fieldName: "licensePlate",
    labelKey: "bfm.vehicleLicensePlate.label",
    name: "bfm.vehicleLicensePlate.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <LicensePlateCell vehicle={row} />;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "fleetName",
    isResizable: true,
    fieldName: "fleetName",
    labelKey: "bfm.fleetName",
    name: "bfm.fleetName",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isFixed: true,
  },
  {
    key: "options",
    isResizable: true,
    fieldName: "options",
    labelKey: "greco.options",
    name: "",
    minWidth: 20,
    maxWidth: 20,
    isVisible: true,
    isFixed: true,
    isPresentInColumnOptions: false,
    isSortable: false,
    excel: {
      isVisible: false,
      isSortable: false,
    },
    // onRender: (row) => {
    //   if (!row) return null;
    //   return (
    //     <span className="action-icon">
    //       <ContextButton vehicle={row} />;
    //     </span>
    //   );
    // },
  },
  {
    key: "insuranceLineIcons",
    isResizable: true,
    fieldName: "insuranceLineIcons",
    labelKey: "",
    name: "",
    minWidth: 320,
    maxWidth: 400,
    isVisible: true,
    isFixed: true,
    isPresentInColumnOptions: false,
    isSortable: false,
    excel: {
      isVisible: false,
      isResizable: true,
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return <InsuranceLineIconsCell vehicle={row} />;
    },
  },
  {
    key: "mtplPolicyStatus",
    isResizable: true,
    iconName: "Sort",
    fieldName: "mtplPolicyStatus",
    labelKey: "bfm.vehicleForm.mtplPolicyStatus.label",
    name: "bfm.vehicleForm.mtplPolicyStatus.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehiclePolicyStatusCode",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(taxonomy.VehiclePolicyStatusCode.byId[row?.mtplPolicyStatus]?.code),
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <CodeCell
          property={"VehiclePolicyStatusCode"}
          propertyCode={row?.mtplPolicyStatus}
        />
      );
    },
  },
  {
    key: "mtplPolicyStatusDateTime",
    isResizable: true,
    fieldName: "mtplPolicyStatusDateTime",
    labelKey: "bfm.vehicleForm.mtplPolicyStatusDateTime.label",
    name: "bfm.vehicleForm.mtplPolicyStatusDateTime.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.mtplPolicyStatusDateTime
          ? formatDateStringWithTime(row?.mtplPolicyStatusDateTime)
          : null,
    },
    isSortable: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.mtplPolicyStatusDateTime
            ? formatDateStringWithTime(row?.mtplPolicyStatusDateTime)
            : null}
        </span>
      );
    },
  },
  {
    key: "hullPolicyStatus",
    isResizable: true,
    iconName: "Sort",
    fieldName: "hullPolicyStatus",
    labelKey: "bfm.vehicleForm.hullPolicyStatus.label",
    name: "bfm.vehicleForm.hullPolicyStatus.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehiclePolicyStatusCode",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(taxonomy.VehiclePolicyStatusCode.byId[row?.hullPolicyStatus]?.code),
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <CodeCell
          property={"VehiclePolicyStatusCode"}
          propertyCode={row?.hullPolicyStatus}
        />
      );
    },
  },
  {
    key: "hullPolicyStatusDateTime",
    isResizable: true,
    fieldName: "hullPolicyStatusDateTime",
    labelKey: "bfm.vehicleForm.hullPolicyStatusDateTime.label",
    name: "bfm.vehicleForm.hullPolicyStatusDateTime.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.hullPolicyStatusDateTime
          ? formatDateStringWithTime(row?.hullPolicyStatusDateTime)
          : null,
    },
    isSortable: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.hullPolicyStatusDateTime
            ? formatDateStringWithTime(row?.hullPolicyStatusDateTime)
            : null}
        </span>
      );
    },
  },
  {
    key: "fleetLegalPolicyStatus",
    isResizable: true,
    iconName: "Sort",
    fieldName: "fleetLegalPolicyStatus",
    labelKey: "bfm.vehicleForm.fleetLegalPolicyStatus.label",
    name: "bfm.vehicleForm.fleetLegalPolicyStatus.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    isSortable: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehiclePolicyStatusCode",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(
          taxonomy.VehiclePolicyStatusCode.byId[row?.fleetLegalPolicyStatus]
            ?.code
        ),
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <CodeCell
          property={"VehiclePolicyStatusCode"}
          propertyCode={row?.fleetLegalPolicyStatus}
        />
      );
    },
  },
  {
    key: "fleetLegalPolicyStatusDateTime",
    isResizable: true,
    fieldName: "fleetLegalPolicyStatusDateTime",
    labelKey: "bfm.vehicleForm.fleetLegalPolicyStatusDateTime.label",
    name: "bfm.vehicleForm.fleetLegalPolicyStatusDateTime.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.fleetLegalPolicyStatusDateTime
          ? formatDateStringWithTime(row?.fleetLegalPolicyStatusDateTime)
          : null,
    },
    isSortable: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.fleetLegalPolicyStatusDateTime
            ? formatDateStringWithTime(row?.fleetLegalPolicyStatusDateTime)
            : null}
        </span>
      );
    },
  },
  {
    key: "vehicleIssuingStatusCode",
    isResizable: true,
    iconName: "Sort",
    fieldName: "vehicleIssuingStatusCode",
    labelKey: "bfm.vehicleForm.vehicleIssuingStatusCode.label",
    name: "bfm.vehicleForm.vehicleIssuingStatusCode.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    isSortable: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehicleIssuingStatus",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(
          taxonomy.VehicleIssuingStatus.byId[row?.vehicleIssuingStatusCode]
            ?.code
        ),
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <CodeCell
          property={"VehicleIssuingStatus"}
          propertyCode={row?.vehicleIssuingStatusCode}
        />
      );
    },
  },
  {
    key: "totalAnnualGrossPremium",
    isResizable: true,
    fieldName: "totalAnnualGrossPremium",
    labelKey: "bfm.totalAnnualGrossPremium",
    name: "bfm.totalAnnualGrossPremium",
    minWidth: 150,
    maxWidth: 200,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.totalAnnualGrossPremium === null) return null;
        return formatNumber(row.totalAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.totalAnnualGrossPremium === null) return null;
      return formatNumber(row.totalAnnualGrossPremium);
    },
  },
  {
    key: "totalMtplRelatedAnnualGrossPremium",
    isResizable: true,
    fieldName: "totalMtplRelatedAnnualGrossPremium",
    labelKey: "bfm.totalMtplRelatedAnnualGrossPremium",
    name: "bfm.totalMtplRelatedAnnualGrossPremium",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.totalMtplRelatedAnnualGrossPremium === null)
          return null;
        return formatNumber(row.totalMtplRelatedAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.totalMtplRelatedAnnualGrossPremium === null) return null;
      return formatNumber(row.totalMtplRelatedAnnualGrossPremium);
    },
  },
  {
    key: "hullAnnualGrossPremium",
    isResizable: true,
    fieldName: "hullAnnualGrossPremium",
    labelKey: "bfm.hullAnnualGrossPremium.label",
    name: "bfm.hullAnnualGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.hullAnnualGrossPremium === null) return null;
        return formatNumber(row.hullAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.hullAnnualGrossPremium === null) return null;
      return formatNumber(row.hullAnnualGrossPremium);
    },
  },
  {
    key: "totalProRataGrossPremium",
    isResizable: true,
    fieldName: "totalProRataGrossPremium",
    labelKey: "bfm.totalProRataGrossPremium",
    name: "bfm.totalProRataGrossPremium",
    minWidth: 150,
    maxWidth: 200,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.totalProRataGrossPremium === null) return null;
        return formatNumber(row.totalProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.totalProRataGrossPremium === null) return null;
      return formatNumber(row.totalProRataGrossPremium);
    },
  },
  {
    key: "totalMtplRelatedProRataGrossPremium",
    isResizable: true,
    fieldName: "totalMtplRelatedProRataGrossPremium",
    labelKey: "bfm.totalMtplRelatedProRataGrossPremium",
    name: "bfm.totalMtplRelatedProRataGrossPremium",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.totalMtplRelatedProRataGrossPremium === null)
          return null;
        return formatNumber(row.totalMtplRelatedProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.totalMtplRelatedProRataGrossPremium === null)
        return null;
      return formatNumber(row.totalMtplRelatedProRataGrossPremium);
    },
  },
  {
    key: "hullProRataGrossPremium",
    isResizable: true,
    fieldName: "hullProRataGrossPremium",
    labelKey: "bfm.hullProRataGrossPremium.label",
    name: "bfm.hullProRataGrossPremium.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.hullProRataGrossPremium === null) return null;
        return formatNumber(row.hullProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.hullProRataGrossPremium === null) return null;
      return formatNumber(row.hullProRataGrossPremium);
    },
  },
  {
    key: "totalBookingAmount",
    isResizable: true,
    fieldName: "totalBookingAmount",
    labelKey: "bfm.totalPendingBookingAmount",
    name: "bfm.totalPendingBookingAmount",
    minWidth: 150,
    maxWidth: 200,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.totalBookingAmount === null) return null;
        return formatNumber(row.totalBookingAmount);
      },
    },
    onRender: (row) => {
      if (!row || row?.totalBookingAmount === null) return null;
      return formatNumber(row.totalBookingAmount);
    },
  },
  {
    key: "mtplRelatedBookingAmount",
    isResizable: true,
    fieldName: "mtplRelatedBookingAmount",
    labelKey: "bfm.mtplRelatedPendingBookingAmount",
    name: "bfm.mtplRelatedPendingBookingAmount",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.mtplRelatedBookingAmount === null) return null;
        return formatNumber(row.mtplRelatedBookingAmount);
      },
    },
    onRender: (row) => {
      if (!row || row?.mtplRelatedBookingAmount === null) return null;
      return formatNumber(row.mtplRelatedBookingAmount);
    },
  },
  {
    key: "hullRelatedBookingAmount",
    isResizable: true,
    fieldName: "hullRelatedBookingAmount",
    labelKey: "bfm.hullRelatedPendingBookingAmount",
    name: "bfm.hullRelatedPendingBookingAmount",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.hullRelatedBookingAmount === null) return null;
        return formatNumber(row.hullRelatedBookingAmount);
      },
    },
    onRender: (row) => {
      if (!row || row?.hullRelatedBookingAmount === null) return null;
      return formatNumber(row.hullRelatedBookingAmount);
    },
  },
  {
    key: "carUsageCode",
    isResizable: true,
    fieldName: "carUsageCode",
    labelKey: "bfm.vehicleCarUsage.label",
    isSortable: true,
    name: "bfm.vehicleCarUsage.label",
    minWidth: 150,
    iconName: "Sort",
    maxWidth: 150,
    isVisible: true,
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "taxonomy",
      isSelect: true,
      taxonomyKey: "VehicleUsageType",
      value: [],
    },
    excel: {
      width: 200,
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.VehicleUsageType.byId[row?.carUsageCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <CodeCell
          property={"VehicleUsageType"}
          propertyCode={row?.carUsageCode}
        />
      );
    },
  },
  {
    key: "vehicleCreatedAt",
    isResizable: true,
    fieldName: "vehicleCreatedAt",
    labelKey: "bfm.vehicleCreatedAt.label",
    name: "bfm.vehicleCreatedAt.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.vehicleCreatedAt ? formatDateString(row?.vehicleCreatedAt) : null,
    },
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.vehicleCreatedAt
            ? formatDateString(row?.vehicleCreatedAt)
            : null}
        </span>
      );
    },
  },
  {
    key: "vehicleLastModifiedAt",
    isResizable: true,
    fieldName: "vehicleLastModifiedAt",
    labelKey: "bfm.vehicleForm.vehicleLastModifiedAt.label",
    name: "bfm.vehicleForm.vehicleLastModifiedAt.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    iconName: "Sort",
    isSortable: true,
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.vehicleLastModifiedAt
          ? formatDateString(row?.vehicleLastModifiedAt)
          : null,
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.vehicleLastModifiedAt
            ? formatDateString(row?.vehicleLastModifiedAt)
            : null}
        </span>
      );
    },
  },
  {
    key: "vehicleLastStatusDate",
    isResizable: true,
    fieldName: "vehicleLastStatusDate",
    labelKey: "bfm.vehicleForm.vehicleLastStatusDate.label",
    name: "bfm.vehicleForm.vehicleLastStatusDate.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.vehicleLastStatusDate
          ? formatDateString(row?.vehicleLastStatusDate)
          : null,
    },
    isSortable: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.vehicleLastStatusDate
            ? formatDateString(row?.vehicleLastStatusDate)
            : null}
        </span>
      );
    },
  },

  {
    key: "hasSharedLicensePlate",
    isResizable: true,
    fieldName: "hasSharedLicensePlate",
    labelKey: "bfm.hasSharedLicensePlate.label",
    name: "bfm.hasSharedLicensePlate.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) =>
        row?.hasSharedLicensePlate ? t("greco.yes") : "",
    },
    onRender: (row) =>
      row?.hasSharedLicensePlate && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "hadSharedLicensePlate",
    isResizable: true,
    fieldName: "hadSharedLicensePlate",
    labelKey: "bfm.hadSharedLicensePlate.label",
    name: "bfm.hadSharedLicensePlate.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) =>
        row?.hadSharedLicensePlate ? t("greco.yes") : "",
    },
    onRender: (row) =>
      row?.hadSharedLicensePlate && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },

  {
    key: "hasIndemnityPremium",
    isResizable: true,
    fieldName: "hasIndemnityPremium",
    labelKey: "bfm.hasIndemnityPremium.label",
    name: "bfm.hasIndemnityPremium.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.hasIndemnityPremium ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.hasIndemnityPremium && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "hasBonusMalusPremium",
    isResizable: true,
    fieldName: "hasBonusMalusPremium",
    labelKey: "bfm.hasBonusMalusPremium.label",
    name: "bfm.hasBonusMalusPremium.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.hasBonusMalusPremium ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.hasBonusMalusPremium && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "hadIndemnityPremium",
    isResizable: true,
    fieldName: "hadIndemnityPremium",
    labelKey: "bfm.hadIndemnityPremium.label",
    name: "bfm.hadIndemnityPremium.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.hadIndemnityPremium ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.hadIndemnityPremium && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "hadBonusMalusPremium",
    isResizable: true,
    fieldName: "hadBonusMalusPremium",
    labelKey: "bfm.hadBonusMalusPremium.label",
    name: "bfm.hadBonusMalusPremium.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.hadBonusMalusPremium ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.hadBonusMalusPremium && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },

  {
    key: "hullIsBasic",
    isResizable: true,
    fieldName: "hullIsBasic",
    labelKey: "bfm.vehicleList.hullIsBasic.label",
    name: "bfm.vehicleList.hullIsBasic.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.hullIsBasic ? t("greco.yes") : ""),
    },
    onRender: (row) => {
      const value = row?.hullLastStartDate === null ? null : row?.hullIsBasic;
      return <CheckColumn value={value} />;
    },
  },
  {
    key: "hullProduct",
    isResizable: true,
    fieldName: "hullProduct",
    labelKey: "bfm.vehicleForm.hullProduct.label",
    name: "bfm.vehicleForm.hullProduct.label",
    minWidth: 150,
    maxWidth: 200,
    isSortable: true,
    iconName: "Sort",
    isVisible: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "HullProduct",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },

    onRender: (row) => {
      if (!row) return;

      return (
        <FluentTooltip
          overflowMode={TooltipOverflowMode.Parent}
          content={row?.hullProduct}
        >
          {row?.hullProduct}
        </FluentTooltip>
      );
    },
  },
  {
    key: "vehicleClassCode",
    isResizable: true,
    fieldName: "vehicleClassCode",
    labelKey: "bfm.vehicleForm.vehicleClassCode.label",
    name: "bfm.vehicleForm.vehicleClassCode.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: true,
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "taxonomy",
      isSelect: true,
      taxonomyKey: "VehicleClass",
      value: [],
    },
    excel: {
      width: 250,
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.VehicleClass.byId[row?.vehicleClassCode]?.code),
    },
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      // return <VehicleClassCode classCode={row?.vehicleClassCode} />;
      return (
        <CodeCell
          property={"VehicleClass"}
          propertyCode={row?.vehicleClassCode}
        />
      );
    },
  },
  {
    key: "needsGrECoUserAttention",
    isResizable: true,
    fieldName: "needsGrECoUserAttention",
    labelKey: "bfm.vehicle.needsGrECoUserAttention.label",
    name: "bfm.vehicle.needsGrECoUserAttention.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: true,
    filter: {
      type: "boolean",
      value: null,
    },
    isSortable: true,
    iconName: "Sort",
    excel: {
      render: ({ t, row }) =>
        row?.needsGrECoUserAttention ? t("greco.yes") : "",
      isSortable: false,
      isResizable: true,
    },
    onRender: (row) => {
      return (
        row?.needsGrECoUserAttention && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },
  {
    key: "vin",
    isResizable: true,
    fieldName: "vin",
    labelKey: "bfm.vehicleVin.label",
    name: "bfm.vehicleVin.label",
    minWidth: 150,
    maxWidth: 200,
    isSortable: true,
    iconName: "Sort",
    isVisible: false,
  },
  {
    key: "vehicleMainGroupCode",
    isResizable: true,
    fieldName: "vehicleMainGroupCode",
    labelKey: "bfm.vehicleForm.vehicleMainGroupCode.label",
    name: "bfm.vehicleForm.vehicleMainGroupCode.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "taxonomy",
      isSelect: true,
      taxonomyKey: "VehicleMainGroup",
      value: [],
    },
    excel: {
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.VehicleMainGroup.byId[row?.vehicleMainGroupCode]?.code),
    },
    onRender: (row) => {
      return <VehicleVin vehicleVin={row?.vehicleMainGroupCode} />;
    },
  },
  {
    key: "vehicleTypeCode",
    isResizable: true,
    fieldName: "vehicleTypeCode",
    labelKey: "bfm.vehicleType.label",
    name: "bfm.vehicleType.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "taxonomy",
      isSelect: true,
      taxonomyKey: "VehicleTypeLegal",
      value: [],
    },
    excel: {
      width: 300,
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.VehicleTypeLegal.byId[row?.vehicleTypeCode]?.code),
    },
    onRender: (row) => {
      return (
        <CodeCell
          property={"VehicleTypeLegal"}
          propertyCode={row?.vehicleTypeCode}
        />
      );
    },
  },
  {
    key: "brandCode",
    isResizable: true,
    fieldName: "brandCode",
    labelKey: "bfm.vehicleBrand.label",
    name: "bfm.vehicleBrand.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "taxonomy",
      isSelect: true,
      taxonomyKey: "VehicleBrand",
      value: [],
    },
    excel: {
      width: 300,
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.VehicleBrand.byId[row?.brandCode]?.code),
    },
    onRender: (row) => {
      // return <BrandCode brandCode={row?.brandCode} />;
      return (
        <CodeCell property={"VehicleBrand"} propertyCode={row?.brandCode} />
      );
    },
  },
  {
    key: "model",
    isResizable: true,
    fieldName: "model",
    labelKey: "bfm.vehicleModel.label",
    name: "bfm.vehicleModel.label",
    minWidth: 170,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "costCenterClient",
    isResizable: true,
    fieldName: "costCenterClient",
    labelKey: "bfm.vehicleForm.costCenterClient.label",
    name: "bfm.vehicleForm.costCenterClient.label",
    minWidth: 200,
    maxWidth: 200,
    placeholder: "bfm.columnOptions.typeToStartSearching",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "leasingCompany",
    isResizable: true,
    fieldName: "leasingCompany",
    labelKey: "bfm.vehicleForm.leasingCompany.label",
    name: "bfm.vehicleForm.leasingCompany.label",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.leasingCompany}
      >
        {row?.leasingCompany}
      </FluentTooltip>
    ),
  },
  // {
  //   key: "leasingContractNumber",
  //   isResizable: true,
  //   fieldName: "leasingContractNumber",
  //   labelKey: "bfm.vehicleForm.leasingContractNumber.label",
  //   name: "bfm.vehicleForm.leasingContractNumber.label",
  //   minWidth: 200,
  //   maxWidth: 200,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   onRender: (row) => (
  //     <FluentTooltip
  //       overflowMode={TooltipOverflowMode.Parent}
  //       content={row?.leasingContractNumber}
  //     >
  //       {row?.leasingContractNumber}
  //     </FluentTooltip>
  //   ),
  // },
  // {
  //   key: "leasingContractStartDate",
  //   isResizable: true,
  //   fieldName: "leasingContractStartDate",
  //   labelKey: "bfm.vehicleForm.leasingContractStartDate.label",
  //   name: "bfm.vehicleForm.leasingContractStartDate.label",
  //   minWidth: 200,
  //   maxWidth: 200,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "date",
  //     date1: null,
  //     date2: null,
  //     operator: "isAfter",
  //   },
  //   excel: {
  //     render: ({ row }) =>
  //       row?.leasingContractStartDate
  //         ? formatDateString(row?.leasingContractStartDate)
  //         : "",
  //   },
  //   onRender: (row) => {
  //     return row?.leasingContractStartDate
  //       ? formatDateString(row?.leasingContractStartDate)
  //       : "";
  //   },
  // },
  // {
  //   key: "leasingContractEndDate",
  //   isResizable: true,
  //   fieldName: "leasingContractEndDate",
  //   labelKey: "bfm.vehicleForm.leasingContractEndDate.label",
  //   name: "bfm.vehicleForm.leasingContractEndDate.label",
  //   minWidth: 200,
  //   maxWidth: 200,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "date",
  //     date1: null,
  //     date2: null,
  //     operator: "isAfter",
  //   },
  //   excel: {
  //     render: ({ row }) =>
  //       row?.leasingContractEndDate
  //         ? formatDateString(row?.leasingContractEndDate)
  //         : "",
  //   },
  //   onRender: (row) => {
  //     return row?.leasingContractEndDate
  //       ? formatDateString(row?.leasingContractEndDate)
  //       : "";
  //   },
  // },
  // {
  //   key: "leasingAmountPaidByLessee",
  //   isResizable: true,
  //   fieldName: "leasingAmountPaidByLessee",
  //   labelKey: "bfm.vehicleForm.leasingAmountPaidByLessee.label",
  //   name: "bfm.vehicleForm.leasingAmountPaidByLessee.label",
  //   minWidth: 150,
  //   maxWidth: 200,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "number",
  //     number1: null,
  //     number2: null,
  //     operator: "gt",
  //   },
  //   excel: {
  //     render: ({ row }) => {
  //       if (!row || row?.leasingAmountPaidByLessee === null) return null;
  //       return formatNumber(row.leasingAmountPaidByLessee);
  //     },
  //   },
  //   onRender: (row) => {
  //     if (!row || row?.leasingAmountPaidByLessee === null) return null;
  //     return formatNumber(row.leasingAmountPaidByLessee);
  //   },
  // },
  // {
  //   key: "eurotaxCode",
  //   isResizable: true,
  //   fieldName: "eurotaxCode",
  //   labelKey: "bfm.vehicleForm.eurotaxCode.label",
  //   name: "bfm.vehicleForm.eurotaxCode.label",
  //   minWidth: 200,
  //   maxWidth: 200,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   onRender: (row) => (
  //     <FluentTooltip
  //       overflowMode={TooltipOverflowMode.Parent}
  //       content={row?.eurotaxCode}
  //     >
  //       {row?.eurotaxCode}
  //     </FluentTooltip>
  //   ),
  // },

  // {
  //   key: "bonusMalusCode",
  //   isResizable: true,
  //   fieldName: "bonusMalusCode",
  //   labelKey: "bfm.vehicleForm.bonusMalusCode.label",
  //   isSortable: true,
  //   name: "bfm.vehicleForm.bonusMalusCode.label",
  //   minWidth: 150,
  //   iconName: "Sort",
  //   maxWidth: 150,
  //   isVisible: false,
  //   placeholder: "bfm.columnOptions.typeToStartSearching",
  //   filter: {
  //     type: "taxonomy",
  //     isSelect: true,
  //     taxonomyKey: "VehicleBonusMalusCode",
  //     value: [],
  //   },
  //   excel: {
  //     width: 200,
  //     render: ({ t, taxonomy, row }) =>
  //       t(taxonomy.VehicleBonusMalusCode.byId[row?.bonusMalusCode]?.code),
  //     isSortable: false,
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return (
  //       <CodeCell
  //         property={"VehicleBonusMalusCode"}
  //         propertyCode={row?.bonusMalusCode}
  //       />
  //     );
  //   },
  // },
  {
    key: "clientComment",
    isResizable: true,
    fieldName: "clientComment",
    labelKey: "bfm.vehicleForm.clientComment.label",
    name: "bfm.vehicleForm.clientComment.label",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.clientComment}
      >
        {row?.clientComment}
      </FluentTooltip>
    ),
  },
  {
    key: "seats",
    isResizable: true,
    fieldName: "seats",
    isSortable: true,
    iconName: "Sort",
    labelKey: "bfm.vehicleForm.seats.label",
    name: "bfm.vehicleForm.seats.label",
    minWidth: 100,
    maxWidth: 100,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
      max: 100,
    },
  },
  {
    key: "powerKW",
    isResizable: true,
    fieldName: "powerKW",
    labelKey: "bfm.vehicleForm.powerKw.label",
    name: "bfm.vehicleForm.powerKw.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
  },
  {
    key: "cubicCapacityCcm",
    isResizable: true,
    fieldName: "cubicCapacityCcm",
    labelKey: "bfm.vehicleForm.cubicCapacityCcm.label",
    name: "bfm.vehicleForm.cubicCapacityCcm.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
  },
  {
    key: "weightCapacityKg",
    isResizable: true,
    fieldName: "weightCapacityKg",
    labelKey: "bfm.vehicleForm.weightCapacityKg.label",
    name: "bfm.vehicleForm.weightCapacityKg.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
  },
  {
    key: "maxGrossWeightKg",
    isResizable: true,
    fieldName: "maxGrossWeightKg",
    labelKey: "bfm.vehicleForm.maxGrossWeightKg.label",
    name: "bfm.vehicleForm.maxGrossWeightKg.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
  },
  {
    key: "emptyWeightKg",
    isResizable: true,
    fieldName: "emptyWeightKg",
    labelKey: "bfm.vehicleForm.emptyWeightKg.label",
    name: "bfm.vehicleForm.emptyWeightKg.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
  },
  {
    key: "yearOfConstruction",
    isResizable: true,
    fieldName: "yearOfConstruction",
    labelKey: "bfm.vehicleForm.yearOfConstruction.label",
    name: "bfm.vehicleForm.yearOfConstruction.label",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      //includeNumber1: true,
      format: "integer",
      includeThousandSeparators: false,
      max: 2100,
    },
  },
  {
    key: "firstRegistrationDate",
    isResizable: true,
    fieldName: "firstRegistrationDate",
    labelKey: "bfm.vehicleForm.firstRegistrationDate.label",
    name: "bfm.vehicleForm.firstRegistrationDate.label",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      render: ({ row }) =>
        row?.firstRegistrationDate
          ? formatDateString(row?.firstRegistrationDate)
          : "",
    },
    onRender: (row) => {
      return row?.firstRegistrationDate
        ? formatDateString(row?.firstRegistrationDate)
        : "";
    },
  },
  {
    key: "listPrice",
    isResizable: true,
    fieldName: "listPrice",
    labelKey: "bfm.vehicleForm.listPrice.label",
    name: "bfm.vehicleForm.listPrice.label",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      // includeNumber1: true,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.listPrice === null) return null;
        const parsedNumber = parseFloat(row.listPrice);
        return formatNumber(parsedNumber);
      },
    },
    onRender: (row) => {
      if (!row || row?.listPrice === null) return null;
      const parsedNumber = parseFloat(row.listPrice);
      return formatNumber(parsedNumber);
    },
  },
  {
    key: "listPriceTypeCode",
    isResizable: true,
    fieldName: "listPriceTypeCode",
    labelKey: "bfm.vehicleForm.listPriceTypeCode.label",
    name: "bfm.vehicleForm.listPriceTypeCode.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "taxonomy",
      taxonomyKey: "ListPriceType",
      value: [],
    },
    excel: {
      render: ({ t, taxonomy, row }) =>
        t(taxonomy.ListPriceType.byId[row?.listPriceTypeCode]?.code),
    },
    onRender: (row) => (
      <CodeCell
        property={"ListPriceType"}
        propertyCode={row?.listPriceTypeCode}
      />
    ),
  },
  {
    key: "specialEquipment",
    isResizable: true,
    fieldName: "specialEquipment",
    labelKey: "bfm.vehicleForm.specialEquipment.label",
    name: "bfm.vehicleForm.specialEquipment.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.specialEquipment === null) return null;
        const parsedNumber = parseFloat(row.specialEquipment);
        return formatNumber(parsedNumber);
      },
    },
    onRender: (row) => {
      if (!row || row?.specialEquipment === null) return null;
      const parsedNumber = parseFloat(row.specialEquipment);
      return formatNumber(parsedNumber);
    },
  },
  {
    key: "secondLicensePlate",
    isResizable: true,
    fieldName: "secondLicensePlate",
    labelKey: "bfm.vehicleForm.secondLicensePlate.label",
    name: "bfm.vehicleForm.secondLicensePlate.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
  },
  {
    key: "vehicleOwner",
    isResizable: true,
    fieldName: "vehicleOwner",
    labelKey: "bfm.vehicleForm.vehicleOwner.label",
    isSortable: true,
    iconName: "Sort",
    name: "bfm.vehicleForm.vehicleOwner.label",
    minWidth: 200,
    maxWidth: 200,
    isVisible: false,
    placeholder: "bfm.columnOptions.typeToStartSearching",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "nationalCodeID",
    isResizable: true,
    fieldName: "nationalCodeID",
    labelKey: "bfm.vehicleForm.nationalCodeId.label",
    isSortable: true,
    iconName: "Sort",
    name: "bfm.vehicleForm.nationalCodeId.label",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
  },
  {
    key: "co2Emission",
    isResizable: true,
    fieldName: "co2Emission",
    labelKey: "bfm.vehicleForm.co2Emission.label",
    name: "bfm.vehicleForm.co2Emission.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 150,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",

      format: "integer",
    },
  },
  {
    key: "certificateNumber",
    isResizable: true,
    fieldName: "certificateNumber",
    labelKey: "bfm.vehicleForm.certificateNumber.label",
    isSortable: true,
    iconName: "Sort",
    name: "bfm.vehicleForm.certificateNumber.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.certificateNumber}
      >
        {row?.certificateNumber}
      </FluentTooltip>
    ),
  },
  {
    key: "comment",
    isResizable: true,
    fieldName: "comment",
    labelKey: "bfm.vehicleForm.comment.label",
    name: "bfm.vehicleForm.comment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.comment}
      >
        {row?.comment}
      </FluentTooltip>
    ),
  },
  {
    key: "horsePowerBasedTaxComment",
    isResizable: true,
    fieldName: "horsePowerBasedTaxComment",
    labelKey: "bfm.vehicleForm.enginepowertaxcomment.label",
    name: "bfm.vehicleForm.enginepowertaxcomment.label",
    minWidth: 150,
    maxWidth: 200,
    isSortable: true,
    iconName: "Sort",
    isVisible: false,
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.horsePowerBasedTaxComment}
      >
        {row?.horsePowerBasedTaxComment}
      </FluentTooltip>
    ),
  },
  {
    key: "mtplComment",
    isResizable: true,
    fieldName: "mtplComment",
    labelKey: "bfm.vehicleForm.mtplcomment.label",
    name: "bfm.vehicleForm.mtplcomment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.mtplComment}
      >
        {row?.mtplComment}
      </FluentTooltip>
    ),
  },

  {
    key: "hullComment",
    isResizable: true,
    fieldName: "hullComment",
    labelKey: "bfm.vehicleForm.hullcomment.label",
    name: "bfm.vehicleForm.hullcomment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.hullComment}
      >
        {row?.hullComment}
      </FluentTooltip>
    ),
  },
  {
    key: "accidentComment",
    isResizable: true,
    fieldName: "accidentComment",
    labelKey: "bfm.vehicleForm.accidentcomment.label",
    name: "bfm.vehicleForm.accidentcomment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.accidentComment}
      >
        {row?.accidentComment}
      </FluentTooltip>
    ),
  },
  {
    key: "assistanceComment",
    isResizable: true,
    fieldName: "assistanceComment",
    labelKey: "bfm.vehicleForm.assistancecomment.label",
    name: "bfm.vehicleForm.assistancecomment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.assistanceComment}
      >
        {row?.assistanceComment}
      </FluentTooltip>
    ),
  },
  {
    key: "legalComment",
    isResizable: true,
    fieldName: "legalComment",
    labelKey: "bfm.vehicleForm.legalcomment.label",
    name: "bfm.vehicleForm.legalcomment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        overflowMode={TooltipOverflowMode.Parent}
        content={row?.legalComment}
      >
        {row?.legalComment}
      </FluentTooltip>
    ),
  },
  {
    key: "mtplGreenCardExpiryDate",
    isResizable: true,
    fieldName: "mtplGreenCardExpiryDate",
    labelKey: "bfm.fleetForm.mtplGreenCardExpiryDate.label",
    name: "bfm.fleetForm.mtplGreenCardExpiryDate.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isBetween",
    },
    excel: {
      render: ({ t, row }) =>
        row?.mtplGreenCardExpiryDate
          ? formatDateString(row?.mtplGreenCardExpiryDate)
          : null,
    },

    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.mtplGreenCardExpiryDate
            ? formatDateString(row?.mtplGreenCardExpiryDate)
            : null}
        </span>
      );
    },
  },
  {
    key: "excludeHorsePowerBasedTaxReason",
    isResizable: true,
    fieldName: "excludeHorsePowerBasedTaxReason",
    labelKey: "bfm.vehicleForm.excludeHorsePowerBasedTaxReason.label",
    name: "bfm.vehicleForm.excludeHorsePowerBasedTaxReason.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "ReasonForMotorTaxExclusion",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(
          taxonomy.ReasonForMotorTaxExclusion.byId[
            row?.excludeHorsePowerBasedTaxReason
          ]?.code
        ),
    },
    onRender: (row) => (
      <ExcludeHorsePowerBasedTaxReason
        code={row?.excludeHorsePowerBasedTaxReason}
      />
    ),
  },
  {
    key: "excludeHorsePowerBasedTax",
    isResizable: true,
    fieldName: "excludeHorsePowerBasedTax",
    labelKey: "bfm.vehicleForm.excludeHorsePowerBasedTax.label",
    name: "bfm.vehicleForm.excludeHorsePowerBasedTax.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) =>
        row?.excludeHorsePowerBasedTax ? t("greco.yes") : "",
    },
    onRender: (row) =>
      row?.excludeHorsePowerBasedTax && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "gap",
    isResizable: true,
    fieldName: "gap",
    labelKey: "bfm.vehicleForm.gap.label",
    name: "bfm.vehicleForm.gap.label",
    minWidth: 80,
    maxWidth: 80,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.gap ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.gap && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "replacementValue",
    isResizable: true,
    fieldName: "replacementValue",
    labelKey: "bfm.vehicleForm.replacementValue.label",
    name: "bfm.vehicleForm.replacementValue.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.replacementValue ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.replacementValue && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "grossNegligence",
    isResizable: true,
    fieldName: "grossNegligence",
    labelKey: "bfm.vehicleForm.grossNegligence.label",
    name: "bfm.vehicleForm.grossNegligence.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.grossNegligence ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.grossNegligence && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "electroPlusPackage",
    isResizable: true,
    fieldName: "electroPlusPackage",
    labelKey: "bfm.vehicleForm.electroPlusPackage.label",
    name: "bfm.vehicleForm.electroPlusPackage.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.electroPlusPackage ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.electroPlusPackage && (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ),
  },
  {
    key: "horsePowerBasedTaxAnnualy",
    isResizable: true,
    fieldName: "horsePowerBasedTaxAnnualy",
    labelKey: "bfm.vehicleForm.horsePowerBasedTaxAnnualy.label",
    name: "bfm.vehicleForm.horsePowerBasedTaxAnnualy.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.horsePowerBasedTaxAnnualy === null) return null;
        return formatNumber(row.horsePowerBasedTaxAnnualy);
      },
    },
    onRender: (row) => {
      if (!row || row?.horsePowerBasedTaxAnnualy === null) return null;
      return formatNumber(row.horsePowerBasedTaxAnnualy);
    },
  },
  {
    key: "horsePowerBasedTaxProRata",
    isResizable: true,
    fieldName: "horsePowerBasedTaxProRata",
    labelKey: "bfm.vehicleForm.horsePowerBasedTaxProRata.label",
    name: "bfm.vehicleForm.horsePowerBasedTaxProRata.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.horsePowerBasedTaxProRata === null) return null;
        return formatNumber(row.horsePowerBasedTaxProRata);
      },
    },
    onRender: (row) => {
      if (!row || row?.horsePowerBasedTaxProRata === null) return null;
      return formatNumber(row.horsePowerBasedTaxProRata);
    },
  },
  {
    key: "hullDeductibleComment",
    isResizable: true,
    fieldName: "hullDeductibleComment",
    labelKey: "bfm.vehicleForm.hullDeductibleComment.label",
    name: "bfm.vehicleForm.hullDeductibleComment.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
  },
  {
    key: "hullDeductibleMaxAmount",
    isResizable: true,
    fieldName: "hullDeductibleMaxAmount",
    labelKey: "bfm.vehicleForm.hullDeductibleMaxAmount.label",
    name: "bfm.vehicleForm.hullDeductibleMaxAmount.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.hullDeductibleMaxAmount === null) return null;
        return formatNumber(row.hullDeductibleMaxAmount);
      },
    },
    onRender: (row) => {
      if (!row || row?.hullDeductibleMaxAmount === null) return null;
      return formatNumber(row.hullDeductibleMaxAmount);
    },
  },
  {
    key: "hullDeductibleMinAmount",
    isResizable: true,
    fieldName: "hullDeductibleMinAmount",
    labelKey: "bfm.vehicleForm.hullDeductibleMinAmount.label",
    name: "bfm.vehicleForm.hullDeductibleMinAmount.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.hullDeductibleMinAmount === null) return null;
        return formatNumber(row.hullDeductibleMinAmount);
      },
    },
    onRender: (row) => {
      if (!row || row?.hullDeductibleMinAmount === null) return null;
      return formatNumber(row.hullDeductibleMinAmount);
    },
  },
  {
    key: "hullDeductiblePercent",
    isResizable: true,
    fieldName: "hullDeductiblePercent",
    labelKey: "bfm.vehicleForm.hullDeductiblePercent.label",
    name: "bfm.vehicleForm.hullDeductiblePercent.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ t, row }) => {
        if (!row || row?.hullDeductiblePercent === null) return null;
        return `${row.hullDeductiblePercent}%`;
      },
    },
    onRender: (row) => {
      if (!row || row?.hullDeductiblePercent === null) return null;
      return `${row.hullDeductiblePercent}%`;
    },
  },
  {
    key: "hullGroupPolicyNumber",
    isResizable: true,
    fieldName: "hullGroupPolicyNumber",
    labelKey: "bfm.hullGroupPolicyNumber",
    name: "bfm.hullGroupPolicyNumber",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "hullPolicyNumber",
    isResizable: true,
    fieldName: "hullPolicyNumber",
    labelKey: "bfm.hullPolicyNumber",
    name: "bfm.hullPolicyNumber",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "isIncludingTax",
    isResizable: true,
    fieldName: "isIncludingTax",
    labelKey: "bfm.vehicleForm.isIncludingTax.label",
    name: "bfm.vehicleForm.isIncludingTax.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.isIncludingTax ? t("greco.yes") : ""),
    },
    onRender: (row) => {
      return <CheckColumn value={row?.isIncludingTax} />;
    },
  },
  {
    key: "isLeasing",
    isResizable: true,
    fieldName: "isLeasing",
    labelKey: "bfm.vehicleForm.isLeasing.label",
    name: "bfm.vehicleForm.isLeasing.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.isLeasing ? t("greco.yes") : ""),
    },
    onRender: (row) => {
      return <CheckColumn value={row?.isLeasing} />;
    },
  },
  {
    key: "legalAnnualGrossPremium",
    isResizable: true,
    fieldName: "legalAnnualGrossPremium",
    labelKey: "bfm.legalAnnualGrossPremium.label",
    name: "bfm.legalAnnualGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.legalAnnualGrossPremium === null) return null;
        return formatNumber(row.legalAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.legalAnnualGrossPremium === null) return null;
      return formatNumber(row.legalAnnualGrossPremium);
    },
  },
  {
    key: "legalProRataGrossPremium",
    isResizable: true,
    fieldName: "legalProRataGrossPremium",
    labelKey: "bfm.legalProRataGrossPremium.label",
    name: "bfm.legalProRataGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.legalProRataGrossPremium === null) return null;
        return formatNumber(row.legalProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.legalProRataGrossPremium === null) return null;
      return formatNumber(row.legalProRataGrossPremium);
    },
  },
  {
    key: "mtplAnnualGrossPremium",
    isResizable: true,
    fieldName: "mtplAnnualGrossPremium",
    labelKey: "bfm.mtplAnnualGrossPremium",
    name: "bfm.mtplAnnualGrossPremium",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.mtplAnnualGrossPremium === null) return null;
        return formatNumber(row.mtplAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.mtplAnnualGrossPremium === null) return null;
      return formatNumber(row.mtplAnnualGrossPremium);
    },
  },
  {
    key: "mtplGroupPolicyNumber",
    isResizable: true,
    fieldName: "mtplGroupPolicyNumber",
    labelKey: "bfm.mtplGroupPolicyNumber",
    name: "bfm.mtplGroupPolicyNumber",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "mtplPolicyNumber",
    isResizable: true,
    fieldName: "mtplPolicyNumber",
    labelKey: "bfm.mtplPolicyNumber",
    name: "bfm.mtplPolicyNumber",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "mtplProRataGrossPremium",
    isResizable: true,
    fieldName: "mtplProRataGrossPremium",
    labelKey: "bfm.mtplProRataGrossPremium.label",
    name: "bfm.mtplProRataGrossPremium.label",

    isSortable: true,
    iconName: "Sort",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.mtplProRataGrossPremium === null) return null;
        return formatNumber(row.mtplProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.mtplProRataGrossPremium === null) return null;
      return formatNumber(row.mtplProRataGrossPremium);
    },
  },
  {
    key: "accidentAnnualGrossPremium",
    isResizable: true,
    fieldName: "accidentAnnualGrossPremium",
    labelKey: "bfm.accidentAnnualGrossPremium.label",
    name: "bfm.accidentAnnualGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      // includeThousandSeparators: false,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.accidentAnnualGrossPremium === null) return null;
        return formatNumber(row.accidentAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.accidentAnnualGrossPremium === null) return null;
      return formatNumber(row.accidentAnnualGrossPremium);
    },
  },
  {
    key: "accidentProRataGrossPremium",
    isResizable: true,
    fieldName: "accidentProRataGrossPremium",
    labelKey: "bfm.accidentProRataGrossPremium.label",
    name: "bfm.accidentProRataGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.accidentProRataGrossPremium === null) return null;
        return formatNumber(row.accidentProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.accidentProRataGrossPremium === null) return null;
      return formatNumber(row.accidentProRataGrossPremium);
    },
  },
  {
    key: "assistanceAnnualGrossPremium",
    isResizable: true,
    fieldName: "assistanceAnnualGrossPremium",
    labelKey: "bfm.assistanceAnnualGrossPremium.label",
    name: "bfm.assistanceAnnualGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      // format: "integer",
      // includeThousandSeparators: false,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.assistanceAnnualGrossPremium === null) return null;
        return formatNumber(row.assistanceAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.assistanceAnnualGrossPremium === null) return null;
      return formatNumber(row.assistanceAnnualGrossPremium);
    },
  },
  {
    key: "assistanceProRataGrossPremium",
    isResizable: true,
    fieldName: "assistanceProRataGrossPremium",
    labelKey: "bfm.assistanceProRataGrossPremium.label",
    name: "bfm.assistanceProRataGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      includeThousandSeparators: false,
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.assistanceProRataGrossPremium === null) return null;
        return formatNumber(row.assistanceProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.assistanceProRataGrossPremium === null) return null;
      return formatNumber(row.assistanceProRataGrossPremium);
    },
  },
  {
    key: "vatReimbursment",
    isResizable: true,
    fieldName: "vatReimbursment",
    labelKey: "bfm.vehicleForm.vatReimbursment.label",
    name: "bfm.vehicleForm.vatReimbursment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.vatReimbursment ? t("greco.yes") : ""),
    },
    onRender: (row) => {
      return <CheckColumn value={row?.vatReimbursment} />;
    },
  },
  {
    key: "vehiclePowerTypeCode",
    isResizable: true,
    fieldName: "vehiclePowerTypeCode",
    labelKey: "bfm.vehicleForm.vehiclePowerTypeCode.label",
    name: "bfm.vehicleForm.vehiclePowerTypeCode.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehiclePowerType",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(taxonomy.VehiclePowerType.byId[row?.vehiclePowerTypeCode]?.code),
    },
    onRender: (row) => {
      return (
        <CodeCell
          property={"VehiclePowerType"}
          propertyCode={row?.vehiclePowerTypeCode}
        />
      );
    },
  },
  {
    key: "isPluginHybrid",
    isResizable: true,
    fieldName: "isPluginHybrid",
    labelKey: "bfm.vehicleForm.isPluginHybrid.label",
    name: "bfm.vehicleForm.isPluginHybrid.label",
    minWidth: 120,
    maxWidth: 120,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    excel: {
      render: ({ t, row }) => (row?.isPluginHybrid ? t("greco.yes") : ""),
    },
    onRender: (row) =>
      row?.isPluginHybrid === true ? (
        <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
      ) : row?.isPluginHybrid === false ? (
        <Icon iconName="Cancel" style={{ color: accent.redDark }} />
      ) : null,
  },
  {
    key: "vehicleIssuingComment",
    isResizable: true,
    fieldName: "vehicleIssuingComment",
    labelKey: "bfm.vehicleForm.vehicleIssuingComment.label",
    name: "bfm.vehicleForm.vehicleIssuingComment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "vehicleIssuingSubstatusCode",
    isResizable: true,
    fieldName: "vehicleIssuingSubstatusCode",
    labelKey: "bfm.issuingSubstatus",
    name: "bfm.issuingSubstatus",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "VehicleIssuingSubstatus",
      value: [],

      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    excel: {
      render: ({ row, t, taxonomy }) =>
        t(
          taxonomy.VehicleIssuingSubstatus.byId[
            row?.vehicleIssuingSubstatusCode
          ]?.code
        ),
    },
    onRender: (row) => {
      return (
        <CodeCell
          property={"VehicleIssuingSubstatus"}
          propertyCode={row?.vehicleIssuingSubstatusCode}
        />
      );
    },
  },
  {
    key: "mtplInsurerPartnerNumber",
    isResizable: true,
    fieldName: "mtplInsurerPartnerNumber",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    labelKey: "bfm.vehicleForm.mtplInsurerPartnerNumber.label",
    name: "bfm.vehicleForm.mtplInsurerPartnerNumber.label",
    minWidth: 200,
    maxWidth: 300,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "mtplInsurerPartnerNumber",
      renderLabel: ({ taxonomy, t }: any) => {
        return <>{t(taxonomy.code)}</>;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <ColumnHoverCard vehicle={row}>
          <LineInsurer partnerNumber={row?.mtplInsurerPartnerNumber} />
        </ColumnHoverCard>
      );
    },
    excel: {
      render: ({ row, t, insurerList }) => {
        const insurer1 = insurerList.find(
          (insurer) =>
            insurer.insurerInternalNumber + "" === row.mtplInsurerPartnerNumber
        );
        const insurerName1 = insurer1 ? insurer1?.insurerName : "";
        return insurerName1;
      },
    },
  },
  {
    key: "hullInsurerPartnerNumber",
    isResizable: true,
    fieldName: "hullInsurerPartnerNumber",
    isVisible: true,
    labelKey: "bfm.vehicleForm.hullInsurerPartnerNumber.label",
    name: "bfm.vehicleForm.hullInsurerPartnerNumber.label",
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 300,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "hullInsurerPartnerNumber",
      renderLabel: ({ taxonomy, t }: any) => {
        return <>{t(taxonomy.code)}</>;
      },
    },

    onRender: (row) => {
      if (!row) return null;
      return (
        <ColumnHoverCard vehicle={row}>
          <LineInsurer partnerNumber={row?.hullInsurerPartnerNumber} />
        </ColumnHoverCard>
      );
    },
    excel: {
      render: ({ row, t, insurerList }) => {
        const insurer1 = insurerList.find(
          (insurer) =>
            insurer.insurerInternalNumber + "" === row.hullInsurerPartnerNumber
        );
        const insurerName1 = insurer1 ? insurer1?.insurerName : "";
        return insurerName1;
      },
    },
  },
  //fleet legal
  {
    key: "fleetLegalGroupPolicyNumber",
    isResizable: true,
    fieldName: "fleetLegalGroupPolicyNumber",
    labelKey: "bfm.fleetLegalGroupPolicyNumber",
    name: "bfm.fleetLegalGroupPolicyNumber",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "fleetLegalPolicyNumber",
    isResizable: true,
    fieldName: "fleetLegalPolicyNumber",
    labelKey: "bfm.fleetForm.fleetLegalPolicyNumber.label",
    name: "bfm.fleetForm.fleetLegalPolicyNumber.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
  },
  {
    key: "fleetLegalIsPolicyIssued",
    isResizable: true,
    fieldName: "fleetLegalIsPolicyIssued",
    labelKey: "bfm.fleetForm.fleetLegalIsPolicyIssued.label",
    name: "bfm.fleetForm.fleetLegalIsPolicyIssued.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    excel: {
      render: ({ t, row }) =>
        row?.fleetLegalIsPolicyIssued ? t("greco.yes") : "",
    },
    onRender: (row) => {
      if (!row) return;

      return <CheckColumn value={row?.fleetLegalIsPolicyIssued} />;
    },
  },
  {
    key: "fleetLegalPolicyDocumentDate",
    isResizable: true,
    fieldName: "fleetLegalPolicyDocumentDate",
    labelKey: "bfm.fleetForm.fleetLegalPolicyDocumentDate.label",
    name: "bfm.fleetForm.fleetLegalPolicyDocumentDate.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isBetween",
    },
    excel: {
      render: ({ t, row }) =>
        row?.fleetLegalPolicyDocumentDate
          ? formatDateString(row?.fleetLegalPolicyDocumentDate)
          : null,
    },

    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.fleetLegalPolicyDocumentDate
            ? formatDateString(row?.fleetLegalPolicyDocumentDate)
            : null}
        </span>
      );
    },
  },
  {
    key: "fleetLegalInsurerPartnerNumber",
    isResizable: true,
    fieldName: "fleetLegalInsurerPartnerNumber",
    labelKey: "bfm.fleetForm.fleetLegalInsurerPartnerNumber.label",
    name: "bfm.fleetForm.fleetLegalInsurerPartnerNumber.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "fleetLegalInsurerPartnerNumber",
      renderLabel: ({ taxonomy, t }: any) => {
        return <>{t(taxonomy.code)}</>;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <ColumnHoverCard vehicle={row}>
          <LineInsurer partnerNumber={row?.fleetLegalInsurerPartnerNumber} />
        </ColumnHoverCard>
      );
    },
    excel: {
      render: ({ row, t, insurerList }) => {
        const insurer1 = insurerList.find(
          (insurer) =>
            insurer.insurerInternalNumber + "" ===
            row.fleetLegalInsurerPartnerNumber
        );
        const insurerName1 = insurer1 ? insurer1?.insurerName : "";
        return insurerName1;
      },
    },
  },
  {
    key: "fleetLegalAnnualGrossPremium",
    isResizable: true,
    fieldName: "fleetLegalAnnualGrossPremium",
    labelKey: "bfm.fleetLegalProRataAnnualGrossPremium.label",
    name: "bfm.fleetLegalProRataAnnualGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.fleetLegalAnnualGrossPremium === null) return null;
        return formatNumber(row?.fleetLegalAnnualGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.fleetLegalAnnualGrossPremium === null) return null;
      return formatNumber(row.fleetLegalAnnualGrossPremium);
    },
  },
  {
    key: "fleetLegalProRataGrossPremium",
    isResizable: true,
    fieldName: "fleetLegalProRataGrossPremium",
    labelKey: "bfm.fleetLegalProrataGrossPremium.label",
    name: "bfm.fleetLegalProrataGrossPremium.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.fleetLegalProRataGrossPremium === null) return null;
        return formatNumber(row?.fleetLegalProRataGrossPremium);
      },
    },
    onRender: (row) => {
      if (!row || row?.fleetLegalProRataGrossPremium === null) return null;
      return formatNumber(row.fleetLegalProRataGrossPremium);
    },
  },
  {
    key: "fleetLegalIsClosingDate",
    isResizable: true,
    fieldName: "fleetLegalIsClosingDate",
    labelKey: "bfm.fleetLegalIsClosingDate.label",
    name: "bfm.fleetLegalIsClosingDate.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    excel: {
      render: ({ t, row }) =>
        row?.fleetLegalIsClosingDate ? t("greco.yes") : "",
    },
    onRender: (row) => {
      if (!row) return;

      return <CheckColumn value={row?.fleetLegalIsClosingDate} />;
    },
  },
  {
    key: "fleetLegalLastStartDate",
    isResizable: true,
    fieldName: "fleetLegalLastStartDate",
    labelKey: "bfm.fleetLegalLastStartDate.label",
    name: "bfm.fleetLegalLastStartDate.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isBetween",
    },
    excel: {
      render: ({ t, row }) =>
        row?.fleetLegalLastStartDate
          ? formatDateString(row?.fleetLegalLastStartDate)
          : null,
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <span>
          {row?.fleetLegalLastStartDate
            ? formatDateString(row?.fleetLegalLastStartDate)
            : null}
        </span>
      );
    },
  },

  {
    key: "fleetLegalComment",
    isResizable: true,
    fieldName: "fleetLegalComment",
    labelKey: "bfm.fleetLegalComment.label",
    name: "bfm.fleetLegalComment.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "fleetLegalRelatedBookingAmount",
    isResizable: true,
    fieldName: "fleetLegalRelatedBookingAmount",
    labelKey: "bfm.fleetLegalRelatedBookingAmount.label",
    name: "bfm.fleetLegalRelatedBookingAmount.label",
    minWidth: 150,
    maxWidth: 200,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
    },
    excel: {
      render: ({ row }) => {
        if (!row || row?.fleetLegalRelatedBookingAmount === null) return null;
        return formatNumber(row?.fleetLegalRelatedBookingAmount);
      },
    },
    onRender: (row) => {
      if (!row || row?.fleetLegalRelatedBookingAmount === null) return null;
      return formatNumber(row.fleetLegalRelatedBookingAmount);
    },
  },
];
// .sort(function (a, b) {
//   if (a.key < b.key) return -1;

//   return 0;
// });
export const defaultColumnsByKey = defaultColumns?.reduce((byKey, c) => {
  byKey[c.key] = c;
  return byKey;
}, {});
