import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useSelector } from "store/hooks";
import { PremiumType } from "types/types";
import { stringToNumber } from "../../../utils/utils";

export const useOneTimeAnnualNetPremium = (premiumTypeKey) => {
  const formik = useFormikContext();
  const annualGrossPremium = Number(
    formik.values["oneTimePremiumGrossValue"] &&
      formik.values["oneTimePremiumGrossValue"] !== ""
      ? stringToNumber(formik.values["oneTimePremiumGrossValue"])
      : 0
  );
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  useEffect(() => {
    if (["Country.AT"].includes(appSettings?.COUNTRY_CODE)) {
      const netPremiumPerc = premiumTypeKey === PremiumType.ACCIDENT ? 4 : 11;
      const annualNetPremium =
        Math.round(
          ((annualGrossPremium / (100 + netPremiumPerc)) * 100 +
            Number.EPSILON) *
            100
        ) / 100;
      const annualNetPremimString = annualNetPremium.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (formik.dirty) {
        //&& !formik.isSubmitting
        formik.setFieldValue("oneTimePremiumNetValue", annualNetPremimString);
      }
    }
  }, [
    annualGrossPremium,
    formik.touched["oneTimePremiumGrossValue"],
    formik.values["oneTimePremiumGrossValue"],
    premiumTypeKey,
  ]);
};
