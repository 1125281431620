import CheckboxField from "components/controls/CheckboxField";
import React, { useEffect } from "react";

const typeSelection = [
  "HULL",
  "HULL_BASIC",
  "MTPL",
  "FLEETLEGAL_PRORATA",
  "FLEETLEGAL_CLOSINGDATE",
];

const IsPolicyIssuedDependencyField = ({
  premiumTypeSelected,
  classesItem,
  errors,
  setFieldValue,
  values,
}) => {
  console.log(premiumTypeSelected);
  const renderField = typeSelection.includes(premiumTypeSelected?.code);

  useEffect(() => {
    if (!renderField) {
      setFieldValue("isPolicyIssued", false);
    }
  }, [premiumTypeSelected, renderField, setFieldValue]);

  if (!renderField) {
    return null;
  }

  return (
    <div className={classesItem}>
      <CheckboxField
        checked={values?.isPolicyIssued}
        errors={errors}
        setFieldValue={setFieldValue}
        name={"isPolicyIssued"}
        disabled={false}
        label={"bfm.vehicleForm.isPolicyIssued.label"}
        isMarked={false}
      />
    </div>
  );
};

export default IsPolicyIssuedDependencyField;
