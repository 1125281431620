import {
  Checkbox,
  DefaultButton,
  DialogFooter,
  mergeStyleSets,
} from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import GroupPolicyNumberField from "components/controls/GroupPolicyNumberField";
import TextInputField from "components/controls/TextInputField";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import { Formik } from "formik";
import { useUpdateFleetPremiumDocumentDate } from "hooks/data/mutations/useUpdateFleetPremiumDocumentDate";
import { delay } from "library/delay";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { setIsTradeLicensePlate } from "store/slices/tradeLicensePlateSlice";
import { createFieldPairValidation } from "store/util";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import { setDialog, setFleetWithPremiums } from "../../../store/fleetForm";
import useActionConfig from "../hooks/useActionConfg";
import DocumentDateDependencyField from "./components/DocumentDateDependencyField";
import FormActionInsurerField from "./components/FormActionInsurerField";
import IsPolicyIssuedDependencyField from "./components/IsPolicyIssuedDependencyField";
import VehicleTypeDependencyField from "./VehicleTypeDependencyField";

const UpdateFleetPremiumDocumentDateDialog = () => {
  const { t } = useTranslation();
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);
  const [isForEngineTax20250401Update, setIsForEngineTax20250401Update] =
    useState(false);
  const taxonomy = useSelector((s) => s.taxonomy);
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const actionsConfig = useActionConfig(fleetAppSettings);
  const dialogData = useSelector((s) => s.fleetForm.dialog);
  const defaultData =
    dialogData.type === "editSetting" && dialogData.data?.selectedSetting;
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  const { mutate: onUpdateFleetPremiumDocumentDate, isLoading } =
    useUpdateFleetPremiumDocumentDate();
  const mtplGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mtplGroupPolicyInfo
  );
  const hullGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.hullGroupPolicyInfo
  );
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const premiumTypeOptions = actionsConfig.premiumTypeOptions;
  const vehiclePolicyStatusOptions = actionsConfig.vehiclePolicyStatusOptions;
  const carUsageOptions = actionsConfig.carUsageOptions;
  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;

  const insurerOptions = actionsConfig.insurerOptions;
  const initialValues = {
    premiumType: null,
    insurerPartnerNumber: null,
    validFromDate: new Date(new Date().getFullYear(), 0, 1),
    documentDate: null,
    isPolicyIssued: false,
    reasonForModificationComment: null,
    insurerGroupPolicyNumber: null,
    policyStatus: null,

    vehicleType: null,
    listPriceType: null,
    listPriceMin: null,
    listPriceMax: null,
    cubicCapacityCcmRange: null,
    powerKwFrom: null,
    powerKwTo: null,
    weightCapacityKgRange: null,
    carUsage: null,
    emptyWeightKgFrom: null,
    emptyWeightKgTo: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup
      .object()
      .shape({
        ...(fleetAppSettings.INCLUDE_GROUP_POLICY && {
          insurerGroupPolicyNumber: yup.mixed().nullable(),
        }),
        premiumType: yup.mixed().required(),
        insurerPartnerNumber: yup.mixed().required(),
        validFromDate: yup
          .mixed()
          .required()
          .test(
            "validFromDate",
            t("bfm.validation.minDate", {
              date: "01.04.2025",
            }),
            function (value) {
              if (isForEngineTax20250401Update) {
                return moment(value, "YYYY-MM-DD").isSameOrAfter(
                  moment("2025-04-01", "YYYY-MM-DD")
                );
              }
              return true;
            }
          ),
        documentDate: yup
          .mixed()
          .nullable()
          .test(
            "documentDate",
            t("bfm.validation.minDateInclusive", {
              date: t("bfm.vehicleForm.premiumActiveOn.label"),
            }),
            function (value) {
              if (isForEngineTax20250401Update && value) {
                const validFromDate = this.parent.validFromDate;
                return moment(value, "YYYY-MM-DD").isSameOrAfter(
                  moment(validFromDate, "YYYY-MM-DD")
                );
              }
              return true;
            }
          ),
        isPolicyIssued: yup.mixed().nullable(),
        reasonForModificationComment: yup.string().max(512).nullable(),

        emptyWeightKgFrom: yup.number().nullable(),
        emptyWeightKgTo: yup.number().nullable(),
        powerKwFrom: yup.number().nullable(),
        powerKwTo: yup.number().nullable(),
        vehicleType: yup.mixed().nullable(),
        cubicCapacityCcmRange: yup.mixed().nullable(),

        weightCapacityKgRange: yup.mixed().nullable(),
      })
      .test(
        "emptyWeightKg",
        createFieldPairValidation("emptyWeightKgFrom", "emptyWeightKgTo")
      )
      .test("powerKw", createFieldPairValidation("powerKwFrom", "powerKwTo"))
      .test(
        "listPrice",
        createFieldPairValidation("listPriceMin", "listPriceMax")
      );
  }, [t, fleetAppSettings, isForEngineTax20250401Update]);
  const classes = getClassNames();

  const onSubmit = (values, isForEngineTax20250401Update) => {
    let body: any = {
      criteria: {
        vehicleTypeCodes: isTradeLicensePlate
          ? null
          : values?.vehicleType?.map((item) => item.value),

        carUsageCodes: values?.carUsage
          ? values?.carUsage?.map((item) => item.value)
          : null,
        weightCapacityKgRangeCodes: values?.weightCapacityKgRange
          ? values?.weightCapacityKgRange?.map((item) => item.value)
          : null,
        maxGrossWeightKgRangeCodes: values?.maxGrossWeightKgRange
          ? values?.maxGrossWeightKgRange?.map((item) => item.value)
          : null,
        powerKws:
          values?.powerKwFrom && values?.powerKwTo
            ? [
                {
                  from: values?.powerKwFrom,
                  to: values?.powerKwTo,
                },
              ]
            : null,
        emptyWeightKgs:
          values?.emptyWeightKgFrom && values?.emptyWeightKgTo
            ? [
                {
                  from: values?.emptyWeightKgFrom,
                  to: values?.emptyWeightKgTo,
                },
              ]
            : null,
        cubicCapacityCcmRangeCodes: values?.cubicCapacityCcmRange
          ? values?.cubicCapacityCcmRange?.map((item) => item.value)
          : null,
        numberOfSeatsRangeCodes: values?.numberOfSeatsRange
          ? values?.numberOfSeatsRange?.map((item) => item.value)
          : null,
        numberOfSeatsMopedCodes: values?.numberOfSeatsMoped
          ? values?.numberOfSeatsMoped?.map((item) => item.value)
          : null,
        listPrices: values?.listPriceType
          ? [
              {
                type: values?.listPriceType?.value
                  ? values?.listPriceType?.value
                  : null,
                min: values?.listPriceMin
                  ? Number(formatDoubleFieldValue(values?.listPriceMin))
                  : undefined,
                max: values?.listPriceMax
                  ? Number(formatDoubleFieldValue(values?.listPriceMax))
                  : undefined,
              },
            ]
          : null,
        isTradeLicensePlate: isTradeLicensePlate,
      },

      premiumType: values?.premiumType.value,
      insurerPartnerNumber: values?.insurerPartnerNumber
        ? values?.insurerPartnerNumber?.value
        : null,

      validFromDate: moment(values?.validFromDate).format("YYYY-MM-DD"),
      documentDate: values?.documentDate
        ? moment(values?.documentDate).format("YYYY-MM-DD")
        : null,
      reasonForModificationComment: values?.reasonForModificationComment,
      isPolicyIssued: values?.isPolicyIssued,
      ...((fleetAppSettings.DEFAULT_COLUMNS.includes("mtplPolicyStatus") ||
        fleetAppSettings.DEFAULT_COLUMNS.includes("hullPolicyStatus")) && {
        policyStatus: values?.policyStatus?.value,
      }),
      isForEngineTax20250401Update: isForEngineTax20250401Update,
    };
    if (fleetAppSettings?.INCLUDE_GROUP_POLICY) {
      body = {
        ...body,
        insurerGroupPolicyNumber: values?.insurerGroupPolicyNumber
          ? values?.insurerGroupPolicyNumber.value
          : null,
      };
    }

    onUpdateFleetPremiumDocumentDate(
      {
        fleetId,
        body: {
          ...body,
        },
      },
      {
        onSuccess: (res) => {
          dispatch(setFleetWithPremiums(res?.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values, isForEngineTax20250401Update);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const {
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          errors,
          setErrors,
        } = props;
        const insurerGroupPolicyNumberOptions = () => {
          if (
            values?.premiumType?.code === "HULL" ||
            values?.premiumType?.code === "HULL_BASIC"
          ) {
            return hullGroupPolicyInfo?.map((policyInfo) => ({
              label: policyInfo.groupPolicyNumber,
              value: policyInfo.groupPolicyNumber,
            }));
          }
          if (values?.premiumType?.code === "MTPL") {
            return mtplGroupPolicyInfo?.map((policyInfo) => ({
              label: policyInfo.groupPolicyNumber,
              value: policyInfo.groupPolicyNumber,
            }));
          }
        };
        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                {fleetAppSettings.INCLUDE_UPDATE_FLEET_PREMIUM_FOR_ENGINE_TAX_20250401 && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className={classes.fieldItem}>
                      <Checkbox
                        label={t(
                          "bfm.vehicleForm.isForEngineTax01042025Update.label"
                        )}
                        styles={{
                          root: {
                            fontWeight: 600,
                          },
                        }}
                        disabled={false}
                        checked={isForEngineTax20250401Update}
                        onChange={(ev) => {
                          if (!isForEngineTax20250401Update) {
                            setFieldValue("premiumType", {
                              value:
                                taxonomy.PremiumType.byCode["PremiumType.MTPL"]
                                  .id,
                              label: t("PremiumType.MTPL"),
                              code: "MTPL",
                            });
                            setFieldValue(
                              "validFromDate",
                              new Date("2025-04-01")
                            );
                          }
                          setFieldValue("vehicleType", []);
                          setFieldValue("carUsage", []);
                          setFieldValue("listPriceType", null);
                          setFieldValue("listPriceMin", null);
                          setFieldValue("listPriceMax", null);
                          setFieldValue("powerKwFrom", null);
                          setFieldValue("powerKwTo", null);
                          setFieldValue("emptyWeightKgFrom", null);
                          setFieldValue("emptyWeightKgTo", null);
                          setFieldValue("cubicCapacityCcmRange", []);
                          setFieldValue("weightCapacityKgRange", []);
                          setFieldValue("numberOfSeatsRange", []);
                          setFieldValue("numberOfSeatsMoped", []);
                          setErrors({});
                          setIsTradeLicensePlate(false);
                          setIsForEngineTax20250401Update(
                            !isForEngineTax20250401Update
                          );
                        }}
                      />
                    </div>
                  </div>
                )}

                {fleetAppSettings.SHOW_TRADE_LICENSE_PLATES ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className={classes.fieldItem}>
                      <Checkbox
                        label={t("bfm.tradeLicensePlates.label")}
                        styles={{
                          root: {
                            fontWeight: 600,
                          },
                        }}
                        disabled={false}
                        checked={isTradeLicensePlate}
                        onChange={(ev) => {
                          setFieldValue("vehicleType", null);
                          setFieldValue("carUsage", null);
                          setTradeLicensePlate(!isTradeLicensePlate);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {!isForEngineTax20250401Update ? (
                  <div className={classes.fieldItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="premiumType"
                      required
                      label={"bfm.fleet.premiumType.label"}
                      setFieldValue={setFieldValue}
                      options={premiumTypeOptions || []}
                      defaultValue={null}
                      confidentiality={null}
                    />
                  </div>
                ) : null}
                {fleetAppSettings.INCLUDE_GROUP_POLICY && (
                  <div className={classes.fieldItem}>
                    <GroupPolicyNumberField
                      id="insurerGroupPolicyNumber"
                      setFieldValue={setFieldValue}
                      options={insurerGroupPolicyNumberOptions() || []}
                      defaultValue={values?.insurerGroupPolicyNumber}
                      errors={errors}
                      name={"insurerGroupPolicyNumber"}
                      disabled={false}
                      label={"bfm.fleet.setting.insurerGroupPolicyNumber.label"}
                      confidentiality={null}
                      isClearable={false}
                    />
                  </div>
                )}
                {fleetAppSettings.DEFAULT_COLUMNS.includes(
                  "mtplPolicyStatus"
                ) ||
                fleetAppSettings.DEFAULT_COLUMNS.includes(
                  "hullPolicyStatus"
                ) ? (
                  <div className={classes.fieldItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="policyStatus"
                      label={"bfm.vehicleForm.policyStatus.label"}
                      setFieldValue={setFieldValue}
                      options={vehiclePolicyStatusOptions || []}
                      defaultValue={null}
                      confidentiality={null}
                    />
                  </div>
                ) : null}
                <div className={classes.fieldItem}>
                  <FormActionInsurerField
                    actionFormType={"updateFleetPremiumDocumentDate"}
                    allInsurerOptions={insurerOptions}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
                {!isForEngineTax20250401Update ? (
                  <>
                    {values.premiumType?.code === "HULL_BASIC" ||
                    values.premiumType?.code === "HULL" ? (
                      <>
                        <div className={classes.fieldItem}>
                          <ComboBoxField
                            disabled={false}
                            errors={errors}
                            name="listPriceType"
                            label={"bfm.vehicleForm.listPriceTypeCode.label"}
                            setFieldValue={setFieldValue}
                            options={listPriceTypeOptions || []}
                            defaultValue={values?.listPriceType}
                            confidentiality={null}
                          />
                        </div>
                        <div
                          className={classes.fieldItem}
                          style={{ display: "flex", gap: "15px" }}
                        >
                          <div style={{ width: "50%" }}>
                            <DoubleNumberInputField
                              setFieldValue={setFieldValue}
                              value={values.listPriceMin}
                              onChange={handleChange}
                              errors={errors}
                              name={"listPriceMin"}
                              disabled={false}
                              label={"bfm.listPriceMin.label"}
                            />
                          </div>
                          <div style={{ width: "50%" }}>
                            <DoubleNumberInputField
                              style={{ width: "50%" }}
                              setFieldValue={setFieldValue}
                              value={values.listPriceMax}
                              onChange={handleChange}
                              errors={errors}
                              name={"listPriceMax"}
                              disabled={false}
                              label={"bfm.listPriceMax.label"}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className={classes.fieldItem}>
                      {!isTradeLicensePlate ? (
                        <ComboBoxField
                          disabled={false}
                          errors={errors}
                          name="carUsage"
                          label={"bfm.vehicleForm.carUsageCode.label"}
                          setFieldValue={setFieldValue}
                          options={carUsageOptions || []}
                          defaultValue={values?.carUsage}
                          confidentiality={null}
                          isMulti={true}
                        />
                      ) : null}
                    </div>
                    {!isTradeLicensePlate ? (
                      <div className={classes.fieldItem}>
                        <ComboBoxField
                          disabled={isTradeLicensePlate}
                          errors={errors}
                          name="vehicleType"
                          label={"bfm.vehicleForm.vehicleTypeCode.label"}
                          setFieldValue={setFieldValue}
                          options={vehicleTypeCodeOptions || []}
                          defaultValue={values?.vehicleType}
                          confidentiality={null}
                          isMulti={true}
                        />
                      </div>
                    ) : null}{" "}
                    <div className={classes.fieldItem}>
                      <VehicleTypeDependencyField
                        values={values}
                        vehicleTypeSelected={values?.vehicleType}
                        fieldItemStyle={classes.fieldItem}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        selectedDependency={defaultData}
                        disabled={false}
                        includeMargins={false}
                      />
                    </div>
                  </>
                ) : null}
                <div className={classes.fieldItem}>
                  <DatePickerField
                    errors={errors}
                    setFieldValue={setFieldValue}
                    defaultValue={values.validFromDate}
                    name={"validFromDate"}
                    disabled={false}
                    label={"bfm.vehicleForm.premiumActiveOn.label"}
                    placeholder={t("bfm.selectDate")}
                    required
                    confidentiality={null}
                  />
                </div>
                <DocumentDateDependencyField
                  premiumTypeSelected={values?.premiumType}
                  classesItem={classes.fieldItem}
                  errors={errors}
                  fieldName={"documentDate"}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <IsPolicyIssuedDependencyField
                  premiumTypeSelected={values?.premiumType}
                  classesItem={classes.fieldItem}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.reasonForModificationComment}
                    onChange={handleChange}
                    errors={errors}
                    name={"reasonForModificationComment"}
                    multiline
                    rows={4}
                    disabled={false}
                    label={"bfm.comment.label"}
                  />
                </div>
                <DialogFooter className={classes.footer}>
                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default UpdateFleetPremiumDocumentDateDialog;
