import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "store/hooks";
import { PremiumType } from "types/types";
import {
  calculateAnnualNetPremium,
  stringToNumber,
} from "../../../utils/utils";

export const useAnnualNetPremium = (
  premiumTypeKey,
  annualGrossPremiumName,
  annualNetPremiumName,
  insurerPartnerNumberName,
  isTempVehicle
) => {
  const formik = useFormikContext();
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);
  const insurerSettings = useSelector((s) => s.vehicle.insurerSettings);
  const taxonomy = useSelector((s) => s.taxonomy);
  const annualGrossPremium = Number(
    formik.values[annualGrossPremiumName] &&
      formik.values[annualGrossPremiumName] !== ""
      ? stringToNumber(formik.values[annualGrossPremiumName])
      : 0
  );

  const formatForDoubleFieldPercentHungarian = (defaultValueOperation) => {
    let annualNetPremium = "";
    if (defaultValueOperation.formValues) {
      const formValues = defaultValueOperation.formValues;
      console.log(formValues);

      let insurer = formValues[insurerPartnerNumberName]?.value;
      if (!insurer) {
        return null;
      }

      const insurerSettingsForInsurer = insurerSettings?.filter(
        (is) => is.insurerPartnerNumber === insurer
      );

      let insurerSetting = null;
      if (insurerSettingsForInsurer.length === 1) {
        insurerSetting = insurerSettingsForInsurer?.find((is) => {
          const a = moment(
            moment(is.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          const b = moment(
            moment(formValues.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          if (
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length > 0
          ) {
            return true;
          }
          return a <= b;
        });
      } else if (insurerSettingsForInsurer.length > 1) {
        // Find latest insurer setting with valid from date before dialog date. Sording the array by validFromDate descending
        insurerSetting = insurerSettingsForInsurer
          ?.sort((a, b) => {
            return (
              moment(b.validFromDate).toDate().getTime() -
              moment(a.validFromDate).toDate().getTime()
            );
          })
          ?.find((is) => {
            const a = moment(
              moment(is.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            const b = moment(
              moment(formValues.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            if (
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length >
                0
            ) {
              return true;
            }
            return a <= b;
          });
      }

      if (insurerSetting) {
        if (
          insurerSetting.insurerSettingPerPremiumTypeDetailsHU &&
          insurerSetting.insurerSettingPerPremiumTypeDetailsHU.length > 0
        ) {
          const setting3 =
            insurerSetting.insurerSettingPerPremiumTypeDetailsHU.find(
              (el) =>
                el.premiumTypeCode ===
                taxonomy.PremiumType.byCode[premiumTypeKey].id
            );

          const annualGrossPremium = parseFloat(
            (formValues[annualGrossPremiumName] as string)
              ?.replaceAll(" ", "")
              ?.replaceAll(".", "")
              .replaceAll(",", ".")
          );
          const maxValueForTaxCalculation = setting3?.maxValueForTaxCalculation;
          const taxPercent = setting3?.taxPercent;
          const taxExtraPercent1 = setting3?.taxExtraPercent1;
          let calculatedPremium = calculateAnnualNetPremium(
            premiumTypeKey,
            taxPercent,
            taxExtraPercent1,
            annualGrossPremium,
            maxValueForTaxCalculation
          );

          //   annualGrossPremium > maxValueForTaxCalculation
          //     ? maxValueForTaxCalculation
          //     : annualGrossPremium;
          // calculatedPremium = calculatedPremium * (1 + taxPercent / 100);
          // calculatedPremium =
          //   calculatedPremium * (1 + taxExtraPercent1 / 100);
          // calculatedPremium =
          //   calculatedPremium * (1 + taxExtraPercent2 / 100);

          const premiumString = calculatedPremium.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          annualNetPremium = premiumString;

          //toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
        }
        // if results attribute value is "undefined" then remove it from the object
        if (annualNetPremium === "undefined") {
          return null;
        }
      }
    }
    return annualNetPremium;
  };

  useEffect(() => {
    if (!isTempVehicle) return;
    if (["Country.AT"].includes(vehicleAppSettings?.COUNTRY_CODE)) {
      const netPremiumPerc = premiumTypeKey === PremiumType.ACCIDENT ? 4 : 11;
      const annualNetPremium =
        Math.round(
          ((annualGrossPremium / (100 + netPremiumPerc)) * 100 +
            Number.EPSILON) *
            100
        ) / 100;
      const annualNetPremimString = annualNetPremium.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (formik.dirty && !formik.isSubmitting) {
        formik.setFieldValue(annualNetPremiumName, annualNetPremimString);
      }
    } else {
      const annualNetPremium = formatForDoubleFieldPercentHungarian({
        formValues: formik.values,
      });
      if (formik.dirty && !formik.isSubmitting) {
        formik.setFieldValue(annualNetPremiumName, annualNetPremium);
      }
    }
  }, [
    isTempVehicle,
    annualGrossPremium,
    annualNetPremiumName,
    formik.touched[annualGrossPremiumName],
    formik.values[annualGrossPremiumName],
    premiumTypeKey,
  ]);
};
