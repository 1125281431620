import styled from "styled-components";

import {
  DatePicker,
  DefaultButton,
  Icon,
  IconButton,
  Persona,
  PersonaSize,
  TooltipHost,
} from "@fluentui/react";
import { Flex, HSpace, VSpace } from "components/styled";
import { isExternalOrReadonlyAdmin } from "form/util";
import { useGetFleetVehicleDefaultSettings } from "hooks/data/queries/getFleetVehicleDefaultSettings";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setDialog, setValidFromDate } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { FleetHullCalculationDefaultSettingType } from "types/types";
import { formatNumber } from "utils/number";
import { DeleteDialog } from "./DeleteDialog";

import moment from "moment";
import { Label } from "office-ui-fabric-react";
import { markOverwrittenCriteria } from "utils/defaultSettingOverwiting";
import { EActionDialog, EDialogAttributeName } from "../../constants";

export const DefaultSettings = ({ settingRef, userRole }) => {
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();
  const insurerList = useSelector((s) => s.fleetForm.insurerList);
  const validFromDate = useSelector((s) => s.fleetForm.validFromDate);
  const { data: defaultSettings, isLoading } =
    useGetFleetVehicleDefaultSettings(fleetId);
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  const taxonomy = useSelector((s) => s.taxonomy);
  const [deleteDialog, setDeleteDialog] = useState({
    isOpen: false,
    setting: null,
  });

  const [sortConfig, setSortConfig] = useState({
    column: null,
    direction: "unsorted",
  });

  // This function will be called when a column header is clicked
  const handleSortClick = (column) => {
    setSortConfig((prevConfig) => {
      // If the same column is clicked, cycle through the sorting states
      if (prevConfig.column === column) {
        const nextDirection =
          prevConfig.direction === "unsorted"
            ? "ascending"
            : prevConfig.direction === "ascending"
            ? "descending"
            : "unsorted";
        return { column, direction: nextDirection };
      } else {
        // If a different column is clicked, start with ascending sort
        return { column, direction: "ascending" };
      }
    });
  };

  const filteredDefaultSettings = useMemo(() => {
    if (defaultSettings) {
      // Convert the input date to a Date object for comparison
      const inputDate = validFromDate;

      // Filter settings to include only those valid on the given date
      let validSettings = defaultSettings
        .filter(
          (setting) =>
            new Date(setting.validFromDate).getTime() <= inputDate.getTime()
        )
        .map((setting) => {
          return {
            ...setting,
            settingCriteria: {
              ...setting.settingCriteria,
              carUsageCodes: setting.settingCriteria.carUsageCodes || [],
              cubicCapacityCcmRangeCodes:
                setting.settingCriteria.cubicCapacityCcmRangeCodes || [],
              emptyWeightKgs: setting.settingCriteria.emptyWeightKgs || [],
              isTradeLicensePlate:
                setting.settingCriteria.isTradeLicensePlate || false,
              listPrices: setting.settingCriteria.listPrices || [],
              maxGrossWeightKgRangeCodes:
                setting.settingCriteria.maxGrossWeightKgRangeCodes || [],
              numberOfSeatsMopedCodes:
                setting.settingCriteria.numberOfSeatsMopedCodes || [],
              numberOfSeatsRangeCodes:
                setting.settingCriteria.numberOfSeatsRangeCodes || [],
              powerKws: setting.settingCriteria.powerKws || [],
              vehicleTypeCodes: setting.settingCriteria.vehicleTypeCodes || [],
              weightCapacityKgRangeCodes:
                setting.settingCriteria.weightCapacityKgRangeCodes || [],
            },
          };
        });

      // Sort the settings by validFromDate in descending order to check for deprecations

      const stringSort = (a, b) => {
        const valueA = a[sortConfig.column]?.toString().toLowerCase();
        const valueB = b[sortConfig.column]?.toString().toLowerCase();

        if (sortConfig.direction === "ascending") {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else if (sortConfig.direction === "descending") {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        } else {
          return 0; // No sorting if the direction is "unsorted"
        }
      };
      const insurerNameSort = (a, b) => {
        const valueA = insurerList.find(
          (insurer) => insurer.insurerInternalNumber === a.insurerPartnerNumber
        ).insurerName;
        const valueB = insurerList.find(
          (insurer) => insurer.insurerInternalNumber === b.insurerPartnerNumber
        ).insurerName;

        if (sortConfig.direction === "ascending") {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else if (sortConfig.direction === "descending") {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        } else {
          return 0; // No sorting if the direction is "unsorted"
        }
      };

      const numberSort = (a, b) => {
        if (sortConfig.direction !== "unsorted") {
          const multiplier = sortConfig.direction === "ascending" ? 1 : -1;
          return (a[sortConfig.column] - b[sortConfig.column]) * multiplier;
        }
      };
      const columnsSortAlgorithms = {
        lastModifiedByUserName: stringSort,
        attributeName: stringSort,
        insurerName: insurerNameSort,
        premiumType: stringSort,
        validFromDate: stringSort,
        attributeValue: numberSort,
      };
      validSettings.sort(columnsSortAlgorithms[sortConfig.column]);

      const result = markOverwrittenCriteria(validSettings);
      return result;
    }
  }, [defaultSettings, validFromDate, sortConfig]);

  const openSettingDialog = (
    type: string,
    attributeName: string,
    title: string,
    settingId?: number,
    selectedSetting?: any,
    isReadOnly?: boolean
  ) => {
    dispatch(
      setDialog({
        type,
        isOpen: true,
        title: isReadOnly
          ? title
          : `${
              settingId
                ? t("bfm.fleet.defaultSettings.edit.label")
                : t("bfm.fleet.defaultSettings.create.label")
            } - ${title}`,
        icon: "Add",
        data: { attributeName, settingId, selectedSetting },
      })
    );
  };

  const openCopySettingDialog = ({
    data,
    title,
  }: {
    title: string;
    data: {
      attributeName: string;
      settingId: null;
      selectedSetting: any;
      hasTradeLicensePlate;
    };
  }) => {
    dispatch(
      setDialog({
        type: EActionDialog.CopySettings,
        isOpen: true,
        title,
        icon: "Add",
        data,
      })
    );
  };

  if (isLoading) {
    return null;
  }

  let menuItems = [
    {
      key: "annualGrossPremium",
      text: t("bfm.annualGrossPremium"),
      onClick: () =>
        openSettingDialog(
          EActionDialog.CreateSetting,
          EDialogAttributeName.AnnualGrossPremium,
          t("bfm.annualGrossPremium")
        ),
      disabled: isExternalOrReadonlyAdmin(userRole),
    },
  ];

  if (fleetAppSettings?.INCLUDE_HULL_DEDUCTIBLE) {
    menuItems.push({
      key: "deductible",
      text: t("bfm.hulldeductible.label"),
      onClick: () =>
        openSettingDialog(
          EActionDialog.CreateSetting,
          EDialogAttributeName.Deductible,
          t("bfm.hulldeductible.label")
        ),
      disabled: isExternalOrReadonlyAdmin(userRole),
    });
  }
  if (fleetAppSettings?.INCLUDE_HULL_CALCULATION) {
    menuItems.push({
      key: "calculation",
      text: t("bfm.hullPremiumCalculation.label"),
      onClick: () =>
        openSettingDialog(
          EActionDialog.CreateSetting,
          EDialogAttributeName.Calculation,
          t("bfm.hullPremiumCalculation.label")
        ),
      disabled: isExternalOrReadonlyAdmin(userRole),
    });
  }
  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : ((date.getDate() + "").length === 1 ? "0" : "") +
          date.getDate() +
          "." +
          ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
  };
  // Function to render the sort icon based on the current sort state
  function renderSortIcon(column) {
    if (sortConfig.column !== column || sortConfig.direction === "unsorted") {
      return <Icon iconName="Sort" />;
    } else if (sortConfig.direction === "ascending") {
      return <Icon iconName="SortDown" />;
    } else if (sortConfig.direction === "descending") {
      return <Icon iconName="SortUp" />;
    }
  }

  return (
    <div
      ref={settingRef}
      id="default-values-tab"
      style={{
        marginTop: "100px",
      }}
    >
      <DeleteDialog
        deleteDialog={deleteDialog}
        close={() => setDeleteDialog({ isOpen: false, setting: null })}
      />
      <Flex
        style={{
          height: "44px",
          padding: "0 8px",
        }}
      >
        <DefaultButton
          text={t("bfm.fleet.defaultSettings.create.label")}
          iconProps={{ iconName: "Add" }}
          menuProps={{
            items: menuItems,
          }}
        />
        <HSpace />
      </Flex>
      <Flex
        style={{
          height: "44px",
          padding: "0 8px",
          marginTop: "20px",
          display: "flex", // Ensure the Flex container is using flexbox layout
          alignItems: "center", // Vertically center align the content
          justifyContent: "space-between", // Distribute space between the left and right sections
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Label>
            {t("bfm.fleet.defaultSettings.displaySettingsOnDate.label")}
          </Label>
          <HSpace />
          <DatePicker
            value={validFromDate}
            onSelectDate={(val) => {
              dispatch(setValidFromDate(val));
            }}
            placeholder="Select a date..."
            formatDate={onFormatDate}
            style={{ width: "150px", marginTop: "5px" }}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          {" "}
          {/* Push this div to the far right */}
          <Label>
            {t("bfm.displayedItemCount.label", [
              filteredDefaultSettings.length,
            ])}
          </Label>
        </div>
      </Flex>
      <Table>
        <thead>
          <tr>
            <th
              style={{ textAlign: "left" }}
              onClick={() => handleSortClick("lastModifiedByUserName")}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                {t("bfm.user")} {renderSortIcon("lastModifiedByUserName")}
              </span>
            </th>
            <th
              style={{ textAlign: "left" }}
              onClick={() => handleSortClick("attributeName")}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                {t("bfm.fleet.defaultSettings.table.attributeName.label")}{" "}
                {renderSortIcon("attributeName")}
              </span>
            </th>
            <th
              style={{ textAlign: "left" }}
              onClick={() => handleSortClick("premiumType")}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                {t("bfm.fleet.premiumType.label")}{" "}
                {renderSortIcon("premiumType")}
              </span>
            </th>
            <th
              style={{ textAlign: "left" }}
              onClick={() => handleSortClick("insurerName")}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                {t("bfm.insurer.label")} {renderSortIcon("insurerName")}
              </span>
            </th>{" "}
            {fleetAppSettings?.INCLUDE_GROUP_POLICY ? (
              <th
                style={{ textAlign: "left" }}
                onClick={() => handleSortClick("insurerGroupPolicyNumber")}
              >
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  {t("bfm.fleet.setting.insurerGroupPolicyNumber.label")}{" "}
                  {renderSortIcon("insurerGroupPolicyNumber")}
                </span>
              </th>
            ) : null}
            <th
              style={{ textAlign: "left", width: "100px" }}
              onClick={() => handleSortClick("validFromDate")}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                {t("bfm.fleet.defaultSettings.validFromDate.label")}{" "}
                {renderSortIcon("validFromDate")}
              </span>
            </th>
            <th
              style={{ textAlign: "left", width: "220px" }}
              onClick={() => handleSortClick("attributeValue")}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                {t("bfm.fleet.defaultSettings.table.attributeValue.label")}{" "}
                {renderSortIcon("attributeValue")}
              </span>
            </th>
            <th style={{ textAlign: "left" }}>{t("bfm.criteria")}</th>
            <th style={{ textAlign: "left", width: "80px" }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredDefaultSettings?.map((s) => {
            const insurerName = insurerList.find(
              (insurer) =>
                insurer.insurerInternalNumber === s.insurerPartnerNumber
            ).insurerName;
            const premiumTypeTaxonomyCode =
              taxonomy.PremiumType.byId[s.premiumType]?.code;

            const insurerGroupPolicyNumber = s.insurerGroupPolicyNumber;

            const carUsageCode = s.settingCriteria.carUsageCodes?.map(
              (e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.carUsageCodes?.includes(e);
                return (
                  <span key={"carUsageCodes" + index}>
                    {isOverwritten ? (
                      <del>{t(taxonomy.VehicleUsageType.byId[e]?.code)}</del>
                    ) : (
                      <span>{t(taxonomy.VehicleUsageType.byId[e]?.code)}</span>
                    )}
                    {index < s.settingCriteria.carUsageCodes.length - 1 && ", "}
                  </span>
                );
              }
            );

            const vehicleTypeTaxonomyCode =
              s.settingCriteria.vehicleTypeCodes?.map((e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.vehicleTypeCodes?.includes(e);
                return (
                  <span key={"vehicleTypeCodes" + index}>
                    {isOverwritten ? (
                      <del>{t(taxonomy.VehicleTypeLegal.byId[e]?.code)}</del>
                    ) : (
                      <span>{t(taxonomy.VehicleTypeLegal.byId[e]?.code)}</span>
                    )}
                    {index < s.settingCriteria.vehicleTypeCodes.length - 1 &&
                      ", "}
                  </span>
                );
              });

            const maxGrossWeightKgRange =
              s.settingCriteria.maxGrossWeightKgRangeCodes?.map((e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.maxGrossWeightKgRangeCodes?.includes(
                    e
                  );
                return (
                  <span key={"maxGrossWeightKgRangeCodes" + index}>
                    {isOverwritten ? (
                      <del>
                        {t(taxonomy.MaxGrossWeightKgRange.byId[e]?.code)}
                      </del>
                    ) : (
                      <span>
                        {t(taxonomy.MaxGrossWeightKgRange.byId[e]?.code)}
                      </span>
                    )}
                    {index <
                      s.settingCriteria.maxGrossWeightKgRangeCodes.length - 1 &&
                      ", "}
                  </span>
                );
              });

            const weightCapacityTaxonomyCode =
              s.settingCriteria.weightCapacityKgRangeCodes?.map((e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.weightCapacityKgRangeCodes?.includes(
                    e
                  );
                return (
                  <span key={"weightCapacityKgRangeCodes" + index}>
                    {isOverwritten ? (
                      <del>
                        {t(taxonomy.WeightCapacityKgRange.byId[e]?.code)}
                      </del>
                    ) : (
                      <span>
                        {t(taxonomy.WeightCapacityKgRange.byId[e]?.code)}
                      </span>
                    )}
                    {index <
                      s.settingCriteria.weightCapacityKgRangeCodes.length - 1 &&
                      ", "}
                  </span>
                );
              });

            const powerKwFrom =
              s?.settingCriteria.powerKws.length > 0
                ? s?.settingCriteria.powerKws[0].from
                : null;
            const powerKwTo =
              s?.settingCriteria.powerKws.length > 0
                ? s?.settingCriteria.powerKws[0].to
                : null;

            const emptyWeightKgFrom =
              s?.settingCriteria.emptyWeightKgs.length > 0
                ? s?.settingCriteria.emptyWeightKgs[0].from
                : null;
            const emptyWeightKgTo =
              s?.settingCriteria.emptyWeightKgs.length > 0
                ? s?.settingCriteria.emptyWeightKgs[0].to
                : null;

            const cubicCapacityCcmRange =
              s.settingCriteria.cubicCapacityCcmRangeCodes?.map((e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.cubicCapacityCcmRangeCodes?.includes(
                    e
                  );
                return (
                  <span key={"cubicCapacityCcmRangeCodes" + index}>
                    {isOverwritten ? (
                      <del>
                        {t(taxonomy.CubicCapacityCcmRangeHg1.byId[e]?.code)}
                      </del>
                    ) : (
                      <span>
                        {t(taxonomy.CubicCapacityCcmRangeHg1.byId[e]?.code)}
                      </span>
                    )}
                    {index <
                      s.settingCriteria.cubicCapacityCcmRangeCodes.length - 1 &&
                      ", "}
                  </span>
                );
              });

            const numberOfSeatsMoped =
              s.settingCriteria.numberOfSeatsMopedCodes?.map((e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.numberOfSeatsMopedCodes?.includes(e);
                return (
                  <span key={"numberOfSeatsMopedCodes" + index}>
                    {isOverwritten ? (
                      <del>{t(taxonomy.NumberOfSeatsMoped.byId[e]?.code)}</del>
                    ) : (
                      <span>
                        {t(taxonomy.NumberOfSeatsMoped.byId[e]?.code)}
                      </span>
                    )}
                    {index <
                      s.settingCriteria.numberOfSeatsMopedCodes.length - 1 &&
                      ", "}
                  </span>
                );
              });

            const numberOfSeatsRange =
              s.settingCriteria.numberOfSeatsRangeCodes?.map((e, index) => {
                const isOverwritten =
                  s.overwrittenCriteria?.numberOfSeatsRangeCodes?.includes(e);
                return (
                  <span key={"numberOfSeatsRangeCodes" + index}>
                    {isOverwritten ? (
                      <del>{t(taxonomy.NumberOfSeatsRange.byId[e]?.code)}</del>
                    ) : (
                      <span>
                        {t(taxonomy.NumberOfSeatsRange.byId[e]?.code)}
                      </span>
                    )}
                    {index <
                      s.settingCriteria.numberOfSeatsRangeCodes.length - 1 &&
                      ", "}
                  </span>
                );
              });

            const listPriceTypeCode =
              s?.settingCriteria.listPrices.length > 0
                ? taxonomy.ListPriceType.byId[
                    s?.settingCriteria.listPrices[0].type
                  ]?.code
                : null;

            const listPriceMin =
              s?.settingCriteria.listPrices.length > 0
                ? s?.settingCriteria.listPrices[0].min
                : null;
            const listPriceMax =
              s?.settingCriteria.listPrices.length > 0
                ? s?.settingCriteria.listPrices[0].max
                : null;

            let tempUser;
            if (s.lastModifiedByUserName) {
              tempUser = s.lastModifiedByUserName;
            } else if (s.lastModifiedByUserEmail) {
              tempUser = s.lastModifiedByUserEmail;
            } else if (s.lastModifiedByUserId) {
              tempUser = s.lastModifiedByUserId;
            }

            const lastModifiedByUser = tempUser;

            let attributeValue: any = s.attributeValue;

            let valueFromDate = moment(s.validFromDate).format("DD.MM.YYYY");
            if (s.attributeName === "annualGrossPremium") {
              attributeValue = formatNumber(Number(s.attributeValue));
            } else if (s.attributeName === "deductible") {
              const attributeValues = JSON.parse(s.attributeValue as string);
              attributeValue = (
                <>
                  {attributeValues.hullDeductibleMinAmount && (
                    <>
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.hullDeductibleMinAmount.label") +
                            ": "}
                        </strong>
                        {attributeValues.hullDeductibleMinAmount}
                      </span>
                      <br />
                    </>
                  )}
                  {attributeValues.hullDeductibleMaxAmount && (
                    <>
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.hullDeductibleMaxAmount.label") +
                            ": "}
                        </strong>
                        {attributeValues.hullDeductibleMaxAmount}
                      </span>
                      <br />
                    </>
                  )}
                  {attributeValues.hullDeductiblePercent && (
                    <>
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.hullDeductiblePercent.label") +
                            ": "}
                        </strong>
                        {attributeValues.hullDeductiblePercent}
                      </span>
                      <br />
                    </>
                  )}
                  {attributeValues.hullDeductibleComment && (
                    <>
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.hullDeductibleComment.label") +
                            ": "}
                        </strong>
                        {attributeValues.hullDeductibleComment}
                      </span>
                      <br />
                    </>
                  )}
                </>
              );
            } else if (s.attributeName === "calculation") {
              const attributeValues = JSON.parse(s.attributeValue as string);
              attributeValue = (
                <>
                  {attributeValues.valueType &&
                    (attributeValues.valueType ===
                    FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE ? (
                      <>
                        <span>
                          <strong>
                            {t(
                              "bfm.hullPremiumCalculation.absoulutePremiumValue.label"
                            ) + ": "}
                          </strong>
                          {attributeValues.absoulutePremiumValue}
                          <br />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <strong>
                            {t(
                              "bfm.hullPremiumCalculation.premiumRatePercentage.label"
                            ) + ": "}
                          </strong>
                          {attributeValues.premiumRatePercentage}
                          <br />
                        </span>
                      </>
                    ))}

                  {attributeValues.premiumFreeSpecialEquipment && (
                    <span>
                      <strong>
                        {t(
                          "bfm.hullPremiumCalculation.premiumFreeSpecialEquipment.label"
                        ) + ": "}
                      </strong>
                      {attributeValues.premiumFreeSpecialEquipment}
                      <br />
                    </span>
                  )}

                  {attributeValues.gap && (
                    <span>
                      <strong>
                        {t("bfm.hullPremiumCalculation.gap.label") + ": "}
                      </strong>
                      {attributeValues.gap}
                      <br />
                    </span>
                  )}

                  {attributeValues.replacementValue && (
                    <span>
                      <strong>
                        {t(
                          "bfm.hullPremiumCalculation.replacementValue.label"
                        ) + ": "}
                      </strong>
                      {attributeValues.replacementValue}
                      <br />
                    </span>
                  )}

                  {attributeValues.grossNegligence && (
                    <span>
                      <strong>
                        {t("bfm.hullPremiumCalculation.grossNegligence.label") +
                          ": "}
                      </strong>
                      {attributeValues.grossNegligence}
                      <br />
                    </span>
                  )}

                  {attributeValues.electroPlusPackage && (
                    <span>
                      <strong>
                        {t(
                          "bfm.hullPremiumCalculation.electroPlusPackage.label"
                        ) + ": "}
                      </strong>
                      {attributeValues.electroPlusPackage}
                      <br />
                    </span>
                  )}

                  {attributeValues.discountForWaiverOfTaxRefund && (
                    <span>
                      <strong>
                        {t(
                          "bfm.hullPremiumCalculation.discountForWaiverOfTaxRefund.label"
                        ) + ": "}
                      </strong>
                      {attributeValues.discountForWaiverOfTaxRefund}
                      <br />
                    </span>
                  )}
                </>
              );
            }
            let attributeTitle;

            switch (s.attributeName) {
              case "annualGrossPremium":
                attributeTitle = "bfm.annualGrossPremium";
                break;
              case "deductible":
                attributeTitle = "bfm.deductible";
                break;
              case "calculation":
                attributeTitle = "bfm.hullPremiumCalculation.label";
                break;

              default:
                attributeTitle = "";
            }

            return (
              <tr
                key={s.fleetVehicleDefaultSettingId}
                style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
              >
                <td>
                  <Flex justify="center">
                    <Persona
                      text={lastModifiedByUser}
                      title={lastModifiedByUser}
                      hidePersonaDetails
                      size={PersonaSize.size32}
                    />
                  </Flex>
                </td>
                <td>
                  <Cell>
                    {t(`bfm.fleet.defaultSettings.table.${s.attributeName}`)}
                  </Cell>
                </td>
                <td>
                  <Cell>
                    {!!!(
                      s.settingCriteria.vehicleTypeCodes &&
                      s.settingCriteria.vehicleTypeCodes.length > 0
                    ) ? (
                      <TooltipHost content={t("bfm.tradeLicensePlates.label")}>
                        <Icon
                          style={{
                            visibility: s.settingCriteria?.isTradeLicensePlate
                              ? "visible"
                              : "hidden",
                          }}
                          iconName="Market"
                        />
                        &nbsp;&nbsp;
                      </TooltipHost>
                    ) : null}

                    {t(premiumTypeTaxonomyCode)}
                  </Cell>
                </td>

                <td>
                  <Cell>{insurerName}</Cell>
                </td>
                {fleetAppSettings?.INCLUDE_GROUP_POLICY ? (
                  <td>
                    <Cell>{s.insurerGroupPolicyNumber}</Cell>
                  </td>
                ) : null}
                <td>
                  <Cell>{valueFromDate}</Cell>
                </td>
                <td>
                  <Cell>{attributeValue}</Cell>
                </td>
                <td>
                  <Cell>
                    {/* {insurerGroupPolicyNumber && (
                      <span>
                        <strong>
                          {t(
                            "bfm.fleet.setting.insurerGroupPolicyNumber.label"
                          ) + ": "}
                        </strong>
                        {insurerGroupPolicyNumber}
                        <br />
                      </span>
                    )} */}
                    {/* <span>
                      <strong>
                        {t("bfm.tradeLicensePlates.label") + ": "}
                      </strong>
                      {s.settingCriteria?.isTradeLicensePlate
                        ? t("greco.yes")
                        : t("greco.no")}
                      <br />
                    </span> */}

                    {vehicleTypeTaxonomyCode &&
                      vehicleTypeTaxonomyCode.length > 0 && (
                        <span>
                          <strong>
                            {t("bfm.vehicleForm.vehicleTypeCode.label") + ": "}
                          </strong>
                          {vehicleTypeTaxonomyCode}
                          <br />
                        </span>
                      )}
                    {carUsageCode && carUsageCode.length > 0 && (
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.carUsageCode.label") + ": "}
                        </strong>
                        {carUsageCode}
                        <br />
                      </span>
                    )}
                    {listPriceTypeCode && (
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.listPriceTypeCode.label") + ": "}
                        </strong>
                        {t(listPriceTypeCode)}
                        <br />
                      </span>
                    )}
                    {(listPriceMin !== null || listPriceMax !== null) && (
                      <span>
                        <strong>{t("bfm.listPrice.label") + ": "}</strong>
                        {listPriceMin} - {listPriceMax}
                        <br />
                      </span>
                    )}
                    {weightCapacityTaxonomyCode &&
                      weightCapacityTaxonomyCode.length > 0 && (
                        <span>
                          <strong>
                            {t("bfm.vehicleForm.weightCapacityKg.label") + ": "}
                          </strong>
                          {weightCapacityTaxonomyCode}
                          <br />
                        </span>
                      )}
                    {maxGrossWeightKgRange &&
                      maxGrossWeightKgRange.length > 0 && (
                        <span>
                          <strong>
                            {t(
                              "bfm.fleet.adjustmentDialog.maxGrossWeightKgRange"
                            ) + ": "}
                          </strong>
                          {maxGrossWeightKgRange}
                          <br />
                        </span>
                      )}
                    {(powerKwFrom || powerKwTo) && (
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.powerKw.label") + ": "}
                        </strong>
                        {powerKwFrom} - {powerKwTo}
                        <br />
                      </span>
                    )}
                    {(emptyWeightKgFrom || emptyWeightKgTo) && (
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.emptyWeightKg.label") + ": "}
                        </strong>
                        {emptyWeightKgFrom} - {emptyWeightKgTo}
                        <br />
                      </span>
                    )}
                    {cubicCapacityCcmRange &&
                      cubicCapacityCcmRange.length > 0 && (
                        <span>
                          <strong>
                            {t("bfm.vehicleForm.cubicCapacityCcm.label") + ": "}
                          </strong>
                          {cubicCapacityCcmRange}
                          <br />
                        </span>
                      )}
                    {numberOfSeatsMoped && numberOfSeatsMoped.length > 0 && (
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.seats.label") + ": "}
                        </strong>
                        {numberOfSeatsMoped}
                        <br />
                      </span>
                    )}
                    {numberOfSeatsRange && numberOfSeatsRange.length > 0 && (
                      <span>
                        <strong>
                          {t("bfm.vehicleForm.seats.label") + ": "}
                        </strong>
                        {numberOfSeatsRange}
                        <br />
                      </span>
                    )}
                  </Cell>
                </td>
                <td>
                  <Flex
                    justify="flex-end"
                    style={{
                      flexWrap: "nowrap",
                    }}
                  >
                    <IconButton
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      iconProps={{ iconName: "View" }}
                      onClick={() => {
                        openSettingDialog(
                          EActionDialog.ReadOnly,
                          s.attributeName,
                          t(attributeTitle),
                          s.fleetVehicleDefaultSettingId,
                          s,
                          true
                        );
                      }}
                    />
                    <IconButton
                      iconProps={{ iconName: "Copy" }}
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      onClick={() => {
                        openCopySettingDialog({
                          data: {
                            attributeName: s.attributeName,
                            settingId: null,
                            selectedSetting: s,
                            hasTradeLicensePlate: !(
                              s.settingCriteria.vehicleTypeCodes &&
                              s.settingCriteria.vehicleTypeCodes.length > 0
                            ),
                          },
                          title: t(attributeTitle),
                        });
                      }}
                    />
                    <IconButton
                      iconProps={{ iconName: "Edit" }}
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      onClick={() => {
                        openSettingDialog(
                          EActionDialog.EditSetting,
                          s.attributeName,
                          t(attributeTitle),
                          s.fleetVehicleDefaultSettingId,
                          s
                        );
                      }}
                    />
                    <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      onClick={() =>
                        setDeleteDialog({
                          isOpen: true,
                          setting: s,
                        })
                      }
                    />
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <VSpace height={200} />
    </div>
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

export const CellStyled = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: left; /* Align text to the left */
  max-width: 100%;
  display: block; /* Use block instead of flex */
  padding: 10px; /* Add padding if necessary */
`;

const Cell = ({ children }) => {
  return (
    // <FluentTooltip content={children}>
    <CellStyled>{children}</CellStyled>
    // </FluentTooltip>
  );
};
